var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [!_vm.hideDefaultActivator ? _c('v-btn', {
    staticClass: "align-self-center",
    attrs: {
      "plain": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialogModel = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" " + _vm._s(_vm.$t("project.project.newCustomView")) + " ")], 1) : _vm._e(), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "800px"
    },
    model: {
      value: _vm.dialogModel,
      callback: function callback($$v) {
        _vm.dialogModel = $$v;
      },
      expression: "dialogModel"
    }
  }, [_c('v-card', {
    attrs: {
      "min-width": "300px"
    }
  }, [_vm.isEditForm ? _c('v-card-title', [_vm._v(_vm._s(_vm.$t("project.project.editCustomView")))]) : _c('v-card-title', [_vm._v(_vm._s(_vm.$t("project.project.newCustomView")))]), _c('div', {
    staticClass: "mx-4"
  }, [_c('v-btn-toggle', {
    staticClass: "my-4",
    attrs: {
      "mandatory": "",
      "borderless": ""
    },
    model: {
      value: _vm.boardToCreate.type,
      callback: function callback($$v) {
        _vm.$set(_vm.boardToCreate, "type", $$v);
      },
      expression: "boardToCreate.type"
    }
  }, [_c('v-btn', {
    attrs: {
      "value": _vm.ProjectCustomViewTypeEnum.TABLE
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-table ")]), _c('span', {
    staticClass: "hidden-sm-and-down"
  }, [_vm._v(_vm._s(_vm.$t("project.project.table")))])], 1), _c('v-btn', {
    attrs: {
      "value": _vm.ProjectCustomViewTypeEnum.BOARD
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-view-column-outline ")]), _c('span', {
    staticClass: "hidden-sm-and-down"
  }, [_vm._v(_vm._s(_vm.$t("project.project.board")))])], 1), _c('v-btn', {
    attrs: {
      "value": _vm.ProjectCustomViewTypeEnum.CALENDAR
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-calendar-month-outline ")]), _c('span', {
    staticClass: "hidden-sm-and-down"
  }, [_vm._v(_vm._s(_vm.$t("project.project.calendar")))])], 1)], 1), _c('v-form', {
    model: {
      value: _vm.validForm,
      callback: function callback($$v) {
        _vm.validForm = $$v;
      },
      expression: "validForm"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "rules": _vm.requiredRule,
      "label": _vm.$t('project.project.customViewName')
    },
    model: {
      value: _vm.boardToCreate.title,
      callback: function callback($$v) {
        _vm.$set(_vm.boardToCreate, "title", $$v);
      },
      expression: "boardToCreate.title"
    }
  }), _c('debug', [_vm._v(" selectedFields "), _c('br'), _vm._v(" " + _vm._s(_vm.selectedFields)), _c('br'), _vm._v(" availableSelectFields"), _c('br'), _vm._v(" " + _vm._s(_vm.availableSelectFields) + " "), _c('br')]), [_vm.ProjectCustomViewTypeEnum.BOARD, _vm.ProjectCustomViewTypeEnum.TABLE, _vm.ProjectCustomViewTypeEnum.CALENDAR].includes(_vm.boardToCreate.type) ? _c('v-autocomplete', {
    attrs: {
      "item-value": "key",
      "item-text": "name",
      "label": _vm.$t('project.project.fields'),
      "hint": _vm.$t('project.project.fieldsHint'),
      "rules": _vm.fieldRule,
      "persistent-hint": "",
      "outlined": "",
      "multiple": "",
      "small-chips": "",
      "return-object": "",
      "items": _vm.availableSelectFields,
      "auto-select-first": "",
      "filter": _vm.getAutocompleteFilter(function (e) {
        return [_vm.$t(e.name), _vm.$t(e.hint)];
      })
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t(item.name)) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t(item.hint)) + " ")])], 1)], 1)], 1)];
      }
    }, {
      key: "selection",
      fn: function fn(data) {
        return [_c('draggable', {
          attrs: {
            "id": data.index,
            "list": _vm.selectedFields,
            "animation": true,
            "group": "group",
            "ghost-class": "ghost",
            "sort": true,
            "move": _vm.move
          },
          on: {
            "change": _vm.change
          }
        }, [_c('v-chip', {
          key: data.item.key,
          attrs: {
            "draggable": ""
          },
          on: {
            "mousedown": function mousedown($event) {
              $event.stopPropagation();
            },
            "click": function click($event) {
              $event.stopPropagation();
            }
          },
          model: {
            value: _vm.selectedFields[data.index],
            callback: function callback($$v) {
              _vm.$set(_vm.selectedFields, data.index, $$v);
            },
            expression: "selectedFields[data.index]"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(data.item.name)) + " ")])], 1)];
      }
    }], null, false, 3004338002),
    model: {
      value: _vm.selectedFields,
      callback: function callback($$v) {
        _vm.selectedFields = $$v;
      },
      expression: "selectedFields"
    }
  }) : _vm._e(), _vm.boardToCreate.type === _vm.ProjectCustomViewTypeEnum.BOARD ? _c('v-autocomplete', {
    attrs: {
      "item-value": "customField.id",
      "item-text": "customField.name",
      "label": "".concat(_vm.$t('project.project.columns'), "*"),
      "hint": _vm.$t('project.project.columnsHint'),
      "persistent-hint": "",
      "rules": _vm.requiredRule,
      "outlined": "",
      "items": _vm.singleSelectFields,
      "auto-select-first": "",
      "filter": _vm.getAutocompleteFilter(function (e) {
        return [_vm.$t(e.customField.name), _vm.$t(e.customField.hint)];
      })
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', [_c('v-list-item', [_c('v-list-item-icon', [_c('custom-field-detail-icon', {
          attrs: {
            "type": item.customField.type
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t(item.customField.name)) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t(item.customField.hint)) + " ")])], 1)], 1)], 1)];
      }
    }], null, false, 163368076),
    model: {
      value: _vm.boardColumn,
      callback: function callback($$v) {
        _vm.boardColumn = $$v;
      },
      expression: "boardColumn"
    }
  }) : _vm._e(), _vm.boardToCreate.type === _vm.ProjectCustomViewTypeEnum.BOARD && _vm.getCustomFieldConfig(_vm.boardColumn) ? _c('v-autocomplete', {
    key: 'v-select-boardColumn-options' + _vm.boardColumn,
    attrs: {
      "item-value": "value",
      "label": _vm.$t('project.project.boardColumnOrder'),
      "hint": _vm.$t('project.project.boardColumnOrderHint'),
      "persistent-hint": "",
      "outlined": "",
      "multiple": "",
      "small-chips": "",
      "return-object": "",
      "items": _vm.getCustomFieldConfig(_vm.boardColumn),
      "auto-select-first": "",
      "filter": _vm.getAutocompleteFilter(function (e) {
        return [_vm.$t(e.description), _vm.$t(e.value)];
      })
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('custom-field-value-chip', {
          attrs: {
            "value": item
          }
        })];
      }
    }, {
      key: "selection",
      fn: function fn(_ref4) {
        var item = _ref4.item,
            index = _ref4.index;
        return [_c('draggable', {
          attrs: {
            "id": index,
            "list": _vm.getCustomFieldConfig(_vm.boardColumn),
            "animation": true,
            "group": "boardcolumngroup",
            "ghost-class": "ghost",
            "sort": true,
            "move": _vm.moveGroupColumnOrder
          },
          on: {
            "change": _vm.changeGroupColumnOrder
          }
        }, [_c('span', {
          key: item.value,
          attrs: {
            "draggable": ""
          },
          on: {
            "mousedown": function mousedown($event) {
              $event.stopPropagation();
            },
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('custom-field-value-chip', {
          attrs: {
            "value": item
          }
        })], 1)])];
      }
    }], null, false, 4207746391),
    model: {
      value: _vm.boardColumnOrder,
      callback: function callback($$v) {
        _vm.boardColumnOrder = $$v;
      },
      expression: "boardColumnOrder"
    }
  }) : _vm._e(), _c('debug', [_vm._v(" boardColumn "), _c('br'), _vm._v(" " + _vm._s(_vm.boardColumn) + " "), _c('br'), _vm._v(" boardColumn Items "), _c('br'), _vm._v(" " + _vm._s(_vm.singleSelectFields) + " "), _c('br')]), _vm.boardToCreate.type === _vm.ProjectCustomViewTypeEnum.CALENDAR ? _c('v-autocomplete', {
    attrs: {
      "item-value": "key",
      "item-text": "name",
      "label": _vm.$t('project.project.calenderStart'),
      "hint": _vm.$t('project.project.calenderStartHint'),
      "rules": _vm.requiredRule,
      "persistent-hint": "",
      "outlined": "",
      "return-object": "",
      "items": _vm.dateFields,
      "auto-select-first": "",
      "filter": _vm.getAutocompleteFilter(function (e) {
        return [_vm.$t(e.name), _vm.$t(e.hint)];
      })
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t(item.name)) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t(item.hint)) + " ")])], 1)], 1)], 1)];
      }
    }, {
      key: "selection",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(_vm.$t(item.name)) + " ")];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-card', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("project.project.dontBeHastyExclamationMarkselectFieldsFirst")) + " ")])], 1)];
      },
      proxy: true
    }], null, false, 620859307),
    model: {
      value: _vm.calendarStart,
      callback: function callback($$v) {
        _vm.calendarStart = $$v;
      },
      expression: "calendarStart"
    }
  }) : _vm._e(), _c('debug', [_vm._v(" calendarStart" + _vm._s(_vm.calendarStart)), _c('br'), _vm._v(" dateFields " + _vm._s(_vm.dateFields) + " ")]), _vm.boardToCreate.type === _vm.ProjectCustomViewTypeEnum.CALENDAR ? _c('v-autocomplete', {
    attrs: {
      "clearable": true,
      "item-value": "key",
      "item-text": "name",
      "label": _vm.$t('project.project.calenderEnd'),
      "hint": _vm.$t('project.project.calenderEndHint'),
      "persistent-hint": "",
      "outlined": "",
      "return-object": "",
      "items": _vm.dateFields,
      "auto-select-first": "",
      "filter": _vm.getAutocompleteFilter(function (e) {
        return [_vm.$t(e.name), _vm.$t(e.hint)];
      })
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t(item.name)) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t(item.hint)) + " ")])], 1)], 1)], 1)];
      }
    }, {
      key: "selection",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm._v(" item.name " + _vm._s(item.name) + " " + _vm._s(_vm.$t(item.name)) + " ")];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-card', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("project.project.dontBeHastyExclamationMarkselectFieldsFirst")) + " ")])], 1)];
      },
      proxy: true
    }], null, false, 939137702),
    model: {
      value: _vm.calendarEnd,
      callback: function callback($$v) {
        _vm.calendarEnd = $$v;
      },
      expression: "calendarEnd"
    }
  }) : _vm._e(), _c('debug', [_vm._v(" availableGroupFieldsForTable " + _vm._s(_vm.availableGroupFieldsForTable) + " ")]), _vm.boardToCreate.type === _vm.ProjectCustomViewTypeEnum.TABLE ? _c('v-autocomplete', {
    attrs: {
      "clearable": true,
      "item-value": "key",
      "item-text": "name",
      "label": "".concat(_vm.$t('project.project.grouping'), " (").concat(_vm.$t('project.project.optional'), ")"),
      "hint": _vm.$t('project.project.groupingHint'),
      "persistent-hint": "",
      "outlined": "",
      "return-object": "",
      "items": _vm.availableGroupFieldsForTable,
      "auto-select-first": "",
      "filter": _vm.getAutocompleteFilter(function (e) {
        return [_vm.$t(e.name), _vm.$t(e.hint)];
      })
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('div', [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t(item.name)) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t(item.hint)) + " ")])], 1)], 1)], 1)];
      }
    }, {
      key: "selection",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(_vm.$t(item.name)) + " ")];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-card', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("project.project.dontBeHastyExclamationMarkselectFieldsFirst")) + " ")])], 1)];
      },
      proxy: true
    }], null, false, 620859307),
    model: {
      value: _vm.groupTableBy,
      callback: function callback($$v) {
        _vm.groupTableBy = $$v;
      },
      expression: "groupTableBy"
    }
  }) : _vm._e(), _vm.boardToCreate.type === _vm.ProjectCustomViewTypeEnum.TABLE ? _c('v-autocomplete', {
    attrs: {
      "item-value": "key",
      "item-text": "name",
      "label": "".concat(_vm.$t('project.project.sortBy'), " (").concat(_vm.$t('project.project.optional'), ")"),
      "hint": _vm.$t('project.project.sortByHint'),
      "persistent-hint": "",
      "clearable": "",
      "outlined": "",
      "return-object": "",
      "items": _vm.availableGroupFieldsForTable,
      "auto-select-first": "",
      "filter": _vm.getAutocompleteFilter(function (e) {
        return [_vm.$t(e.name), _vm.$t(e.hint)];
      })
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('div', [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t(item.name)) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t(item.hint)) + " ")])], 1)], 1)], 1)];
      }
    }, {
      key: "selection",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(_vm.$t(item.name)) + " ")];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-card', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("project.project.dontBeHastyExclamationMarkselectFieldsFirst")) + " ")])], 1)];
      },
      proxy: true
    }, {
      key: "append-outer",
      fn: function fn() {
        return [_vm.boardToCreate.sortBy ? _c('v-btn', {
          staticStyle: {
            "margin-top": "-18px",
            "margin-bottom": "18px"
          },
          attrs: {
            "height": "56px",
            "elevation": 0
          },
          on: {
            "click": _vm.toggleOrder
          }
        }, [_vm.boardToCreate.sortBy.order === _vm.PageOrderEnum.DESCENDING ? _c('v-icon', [_vm._v(" mdi-arrow-down ")]) : _c('v-icon', [_vm._v(" mdi-arrow-up ")])], 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 4049686673),
    model: {
      value: _vm.sortTableBy,
      callback: function callback($$v) {
        _vm.sortTableBy = $$v;
      },
      expression: "sortTableBy"
    }
  }) : _vm._e(), _c('filter-card-pagination', {
    attrs: {
      "filterList": _vm.filterOptions,
      "filter": _vm.boardToCreate.filters,
      "hideDivider": true,
      "isSerachActivated": false,
      "predefinedFilter": _vm.predefinedFilter,
      "large": true
    },
    on: {
      "update:filter": function updateFilter($event) {
        return _vm.$set(_vm.boardToCreate, "filters", $event);
      }
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        _vm.dialogModel = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("abort")) + " ")]), _c('v-btn', {
    attrs: {
      "small": "",
      "elevation": "0",
      "color": "info",
      "loading": _vm.loading,
      "disabled": !_vm.validForm
    },
    on: {
      "click": _vm.updateOrCreate
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")])], 1), _c('debug', [_vm._v(" " + _vm._s(_vm.boardToCreate) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }