import { render, staticRenderFns } from "./FleetHomeVehicleTableActions.vue?vue&type=template&id=1920afd4&scoped=true&"
import script from "./FleetHomeVehicleTableActions.vue?vue&type=script&lang=ts&"
export * from "./FleetHomeVehicleTableActions.vue?vue&type=script&lang=ts&"
import style0 from "./FleetHomeVehicleTableActions.vue?vue&type=style&index=0&id=1920afd4&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1920afd4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VListItem,VListItemIcon,VListItemSubtitle})
