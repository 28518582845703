var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pa-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "justify": "right",
      "align": "right"
    }
  }, [_c('analytics-date-range-selector-dialog', {
    attrs: {
      "dates": _vm.dates
    },
    on: {
      "setDate": _vm.setDateRange
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('statistics-card-row', {
    attrs: {
      "data": _vm.rowData,
      "loading": _vm.loading
    }
  })], 1)], 1), _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.READ,
      "a": _vm.ResourceEnum.REPORT,
      "field": _vm.partnerId
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('time-series-line-chart-card', {
    attrs: {
      "loading": _vm.dataHandler.reportTrendAnalysis.isLoading,
      "data": _vm.reportTrendData,
      "title": "components.dashboard.DashboardStatisticsList.reports"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('pie-chart-card', {
    attrs: {
      "loading": _vm.dataHandler.statusDistribution.isLoading,
      "data": _vm.statusDistributionData,
      "title": "views.portal.PartnerAnalytics.title.statusTypeDistribution"
    }
  })], 1), _c('v-col', {
    attrs: {
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('bar-chart-card', {
    attrs: {
      "loading": _vm.dataHandler.hourDistribution.isLoading,
      "data": _vm.hourDistributionData,
      "title": "views.portal.PartnerAnalytics.title.hourDistribution",
      "reverseAxis": true,
      "sort": false,
      "showMaxItems": 24
    }
  })], 1), _c('v-col', {
    attrs: {
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('bar-chart-card', {
    attrs: {
      "loading": _vm.dataHandler.weekdayDistribution.isLoading,
      "data": _vm.weekdayDistributionData,
      "title": "views.portal.PartnerAnalytics.title.weekdayDistribution",
      "reverseAxis": true,
      "sort": false
    }
  })], 1)], 1)], 1), _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.READ,
      "a": _vm.ResourceEnum.SIGN,
      "field": _vm.partnerId
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('time-series-line-chart-card', {
    attrs: {
      "loading": _vm.dataHandler.signTrendAnalysis.isLoading,
      "data": _vm.signTrendData,
      "title": "sign.DigitalSignatureHomeView.showAllSignatures"
    }
  })], 1)], 1)], 1), _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.READ,
      "a": _vm.ResourceEnum.BOOKING,
      "field": _vm.partnerId
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('time-series-line-chart-card', {
    attrs: {
      "loading": _vm.dataHandler.bookingTrendAnalysis.isLoading,
      "data": _vm.bookingTrendData,
      "title": "common.nouns.appointments"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }