var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('m-detail-table-formable', {
    ref: "table",
    attrs: {
      "title": _vm.$t('about'),
      "item": _vm.value,
      "formable": _vm.formable,
      "outlined": true,
      "omit": ['partnerId', '.lastModified', 'id', 'isSepaDirectDebitMandate'],
      "showCustomFields": false,
      "onDetail": _vm.onDetail
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }