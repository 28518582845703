var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('m-detail-table', {
    key: '' + _vm.detailTableConfig.length + Math.random(),
    attrs: {
      "title": _vm.title,
      "description": _vm.description,
      "item": _vm.item,
      "config": _vm.detailTableConfig,
      "showMoreAction": _vm._showMoreAction,
      "customFieldValues": _vm.showCustomFields ? _vm.item.values : []
    },
    scopedSlots: _vm._u([_vm._l(_vm.detailTableConfig, function (config) {
      return {
        key: "item.".concat(config.key),
        fn: function fn(_ref) {
          var value = _ref.value;
          return [_vm._t("item.".concat(config.key), null, {
            "config": config,
            "item": _vm.item,
            "value": value
          })];
        }
      };
    }), {
      key: "additionalTableRows",
      fn: function fn() {
        return [_vm._l(_vm.nested, function (nested, index) {
          return _c('tr', {
            key: '' + index + Math.random()
          }, [_c('td', [_vm._v(_vm._s(_vm.$t(nested.label)) + " #" + _vm._s(nested.counter + 1))]), _c('td', {
            attrs: {
              "colspan": "2"
            }
          }, [_vm._t("item.".concat(nested.key), function () {
            return [_c('v-card', {
              staticClass: "my-1",
              attrs: {
                "outlined": "",
                "flat": ""
              }
            }, [_c('m-detail-table-formable', {
              attrs: {
                "item": nested.item,
                "formable": nested.formable
              }
            })], 1)];
          }, {
            "index": nested.counter,
            "item": _vm.item,
            "value": nested.item
          })], 2)]);
        }), _vm._t("additionalTableRows")];
      },
      proxy: true
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }