var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', {
    attrs: {
      "subheader": ""
    }
  }, [_c('v-row', {
    staticClass: "d-flex align-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    attrs: {
      "inset": "",
      "label": "Subheader"
    },
    model: {
      value: _vm.isDisplaySubheader,
      callback: function callback($$v) {
        _vm.isDisplaySubheader = $$v;
      },
      expression: "isDisplaySubheader"
    }
  }, [_vm._v("Subheader")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    attrs: {
      "inset": "",
      "label": "Small"
    },
    model: {
      value: _vm.isSmall,
      callback: function callback($$v) {
        _vm.isSmall = $$v;
      },
      expression: "isSmall"
    }
  }, [_vm._v("Small")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    attrs: {
      "inset": "",
      "label": "Click"
    },
    model: {
      value: _vm.isClick,
      callback: function callback($$v) {
        _vm.isClick = $$v;
      },
      expression: "isClick"
    }
  }, [_vm._v("Click")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    attrs: {
      "inset": "",
      "label": "OpenDetail"
    },
    model: {
      value: _vm.isOpenDetail,
      callback: function callback($$v) {
        _vm.isOpenDetail = $$v;
      },
      expression: "isOpenDetail"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "clearable": "",
      "items": _vm.resourceItems,
      "attach": "",
      "chips": "",
      "label": "Resources",
      "multiple": "",
      "outlined": ""
    },
    model: {
      value: _vm.resources,
      callback: function callback($$v) {
        _vm.resources = $$v;
      },
      expression: "resources"
    }
  })], 1)], 1), _c('v-divider'), _vm.isLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-two-line@5"
    }
  }) : _vm._e(), _vm._l(_vm.allRefs, function (ref, i) {
    return [_vm.isDisplaySubheader && ref ? _c('v-subheader', {
      key: 'header' + i
    }, [_vm._v(_vm._s(ref.refType.toLocaleUpperCase()))]) : _vm._e(), ref.refType === _vm.ResourceEnum.REPORT ? _c('refs-report', {
      key: i,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }) : ref.refType === _vm.ResourceEnum.VEHICLE ? _c('refs-vehicle', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.EVENT ? _c('refs-event', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.TICKET ? _c('refs-ticket', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.COST ? _c('refs-cost', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.COST_GROUP ? _c('refs-cost-group', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.DOCUMENT ? _c('refs-document', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.HANDOVER ? _c('refs-handover', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.SIGN ? _c('refs-sign-request', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.MESSAGE ? _c('refs-message', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.COMPANY ? _c('refs-company', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.COMPANY_GROUP ? _c('refs-company-group', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.ATTACHMENT_REQUEST ? _c('refs-attachment-request', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.PERSON ? _c('refs-person', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.PERSON_GROUP ? _c('refs-person-group', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.PERSON_EMAIL ? _c('refs-person-email', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.PERSON_PHONE ? _c('refs-person-phone', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.COMPANY_ADDRESS ? _c('refs-company-address', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.GROUP ? _c('refs-group', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.CONTRACT ? _c('refs-contract', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : ref.refType === _vm.ResourceEnum.FLEET ? _c('refs-fleet', _vm._g({
      key: i,
      ref: "refDetail",
      refInFor: true,
      attrs: {
        "item": ref.item,
        "small": _vm.isSmall
      }
    }, {
      click: _vm.isClick ? _vm.$log.debug(ref.item) : null,
      openDetail: _vm.isOpenDetail ? _vm.$log.debug(ref.item) : null
    })) : _vm._e()];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }