var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table-wrapper', {
    attrs: {
      "headers": _vm.headers,
      "allItems": _vm.extendedItems,
      "selectedItems": _vm.selected,
      "hideTableHeader": true,
      "hideTableFooter": true,
      "disablePagination": true,
      "outlined": false,
      "margin": false,
      "items-per-page": 15,
      "groupBy": _vm.groupBy,
      "sortBy": _vm.sortBy,
      "sortDesc": _vm.sortDesc,
      "customGroupMethod": _vm.customGroupMethod
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selected = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selected = $event;
      },
      "click:row": function clickRow($event) {
        return _vm.$emit('openDetail', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "group.header",
      fn: function fn(_ref) {
        var groupBy = _ref.groupBy,
            group = _ref.group,
            toggle = _ref.toggle,
            isOpen = _ref.isOpen,
            items = _ref.items,
            headers = _ref.headers,
            remove = _ref.remove,
            isMobile = _ref.isMobile,
            hover = _ref.hover;
        return [_vm._t("group.header", null, {
          "group": group,
          "groupBy": groupBy,
          "toggle": toggle,
          "isOpen": isOpen,
          "items": items,
          "headers": headers,
          "remove": remove,
          "isMobile": isMobile,
          "hover": hover
        })];
      }
    }, _vm._l(_vm.headers, function (header) {
      return {
        key: "item.".concat(header.value),
        fn: function fn(_ref2) {
          var item = _ref2.item,
              value = _ref2.value;
          return [_vm._t("item.".concat(header.value), function () {
            return [Array.isArray(_vm.getNestedObjectValues(item, header.value)) ? _c('span', {
              key: item.id + 'slot.item.' + header.value
            }, [_vm._v(" " + _vm._s(_vm.getNestedObjectValues(item, header.value).join(", ")) + " ")]) : _vm._e()];
          }, {
            "item": item,
            "value": value
          })];
        }
      };
    }), {
      key: "item.body",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._t("item.body", function () {
          return [_c('v-row', {
            staticStyle: {
              "overflow-y": "scroll",
              "max-height": "30px",
              "max-width": "350px"
            },
            domProps: {
              "innerHTML": _vm._s(item.body)
            }
          })];
        }, {
          "item": item
        })];
      }
    }, {
      key: "item.timestamp.created",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._t("item.timestamp.created", function () {
          return [item && item.timestamp && item.timestamp.created ? _c('span', [_vm._v(" " + _vm._s(_vm.simpleDoubleDigitDate(item.timestamp.created)) + " ")]) : _vm._e()];
        }, {
          "item": item
        })];
      }
    }, _vm._l(_vm.customFieldSingleSelectHeaders, function (header, index) {
      return {
        key: 'item.' + header.value,
        fn: function fn(_ref5) {
          var item = _ref5.item;
          return [_c('span', {
            key: index
          }, [_vm.customFieldValue(header, item) ? _c('v-chip', {
            attrs: {
              "dark": "",
              "color": _vm.customFieldColor(header, item),
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.customFieldValue(header, item)) + " ")]) : _vm._e()], 1)];
        }
      };
    }), _vm._l(Object.values(_vm.BackendResourceEnum), function (refType, index) {
      return {
        key: 'item.refs?' + refType,
        fn: function fn(_ref6) {
          var item = _ref6.item;
          return [_c('span', {
            key: 'refs?' + refType + index
          }, _vm._l(item.refs, function (ref) {
            return _c('div', {
              key: item.id + ref.refId + ref.refType
            }, [ref.refType === refType ? _c('refs-list', {
              attrs: {
                "small": true,
                "refType": refType,
                "itemPromise": function itemPromise() {
                  return _vm.getRefsItem(ref.refType, ref.refId);
                }
              }
            }) : _vm._e()], 1);
          }), 0)];
        }
      };
    }), _vm._l(_vm.customFields, function (cf, index) {
      return {
        key: 'item.values.value?' + cf.id,
        fn: function fn(_ref7) {
          var item = _ref7.item;
          return [_c('span', {
            key: 'item.values.value?' + cf.id + index
          }, [!item || !_vm.getCustomFieldValueFromItem(item, cf.id) ? _c('div') : cf.type === _vm.CustomFieldEnum.DATE ? _c('div', [_vm._v(" " + _vm._s(_vm.simpleDoubleDigitDate(_vm.getCustomFieldValueFromItem(item, cf.id))) + " ")]) : cf.type === _vm.CustomFieldEnum.DATE_TIME ? _c('div', [_vm._v(" " + _vm._s(_vm.simpleDoubleDigitDate(_vm.getCustomFieldValueFromItem(item, cf.id))) + " " + _vm._s(_vm.formatHoursAndMinutes(_vm.getCustomFieldValueFromItem(item, cf.id))) + " ")]) : [_vm.CustomFieldEnum.SINGLE_SELECT].includes(cf.type) ? _c('div', [cf.configuration && cf.configuration.values ? _c('custom-field-value-chip', {
            attrs: {
              "value": cf.configuration.values.find(function (c) {
                return c.value === _vm.getCustomFieldValueFromItem(item, cf.id);
              })
            }
          }) : _vm._e()], 1) : [_vm.CustomFieldEnum.MULTI_SELECT].includes(cf.type) ? _c('div', [!cf.configuration ? _c('div') : typeof _vm.getCustomFieldValueFromItem(item, cf.id) === 'string' ? _c('custom-field-value-chip', {
            attrs: {
              "value": cf.configuration.values.find(function (c) {
                return c.value === _vm.getCustomFieldValueFromItem(item, cf.id);
              })
            }
          }) : _c('div', _vm._l(_vm.getCustomFieldValueFromItem(item, cf.id), function (value, index) {
            return _c('custom-field-value-chip', {
              key: 'valye' + value,
              class: index ? 'ml-1' : '',
              attrs: {
                "value": cf.configuration.values.find(function (c) {
                  return c.value === value;
                })
              }
            });
          }), 1)], 1) : _vm._e(), cf.type === _vm.CustomFieldEnum.BOOLEAN ? _c('div', [_vm._v(" " + _vm._s(_vm.transformBooleanToText(_vm.getCustomFieldValueFromItem(item, cf.id))) + " ")]) : _vm._e(), [_vm.CustomFieldEnum.NUMBER, _vm.CustomFieldEnum.TEXT].includes(cf.type) ? _c('div', [_c('span', {
            staticClass: "d-inline-block text-truncate",
            staticStyle: {
              "max-width": "350px"
            }
          }, [_vm._v(" " + _vm._s(_vm.getCustomFieldValueFromItem(item, cf.id)) + " ")])]) : _vm._e()])];
        }
      };
    })], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }