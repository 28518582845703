var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_vm.isLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  }) : !_vm.store ? _c('div', [_c('latest-entries-card-empty'), _c('debug', [_vm._v(" Store must be one of the following: " + _vm._s(_vm.GroupTypes.join(", ")) + " ")])], 1) : _vm.group ? _c('div', [_c('m-header', {
    attrs: {
      "breadCrumbs": _vm.breadCrumbList,
      "title": _vm.group.title,
      "actions": _vm.actions,
      "viewId": _vm.viewIdLocal,
      "chips": _vm.chips
    },
    on: {
      "update:viewId": function updateViewId($event) {
        _vm.viewIdLocal = $event;
      },
      "update:view-id": function updateViewId($event) {
        _vm.viewIdLocal = $event;
      },
      "actionClicked": function actionClicked(action) {
        return action.exec();
      }
    }
  }), _c('custom-views', {
    ref: "customViews",
    attrs: {
      "store": _vm.store,
      "entity": _vm.group,
      "refType": _vm.type,
      "viewId": _vm.viewIdLocal,
      "boardBaseConfig": _vm.boardBaseConfig,
      "calendarBaseConfig": _vm.calendarBaseConfig,
      "tableBaseConfig": _vm.tableBaseConfig
    },
    on: {
      "update:viewId": function updateViewId($event) {
        _vm.viewIdLocal = $event;
      },
      "update:view-id": function updateViewId($event) {
        _vm.viewIdLocal = $event;
      },
      "openSideCard": _vm.openSideCard,
      "goToSettings": _vm.goToSettings
    },
    scopedSlots: _vm._u([{
      key: "item.groupId",
      fn: function fn(_ref) {
        var item = _ref.item,
            value = _ref.value;
        return [value ? _c('refs-selected', {
          staticStyle: {
            "max-width": "250px"
          },
          attrs: {
            "value": [{
              refId: value,
              refType: _vm.ResourceEnum.GROUP
            }],
            "confirmable": false,
            "draggable": false,
            "removable": false,
            "small": true,
            "partnerId": item.partnerId,
            "outlined": false
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.firstName",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.openSideCard(item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.firstName) + " ")])];
      }
    }, {
      key: "item.lastName",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.openSideCard(item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.lastName) + " ")])];
      }
    }, {
      key: "item.timestamp.created",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(item.timestamp.created)) + " ")];
      }
    }, {
      key: "item.assignees",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item && item.assignees && item.assignees.length ? _c('assignee-preview', {
          attrs: {
            "value": item.assignees,
            "amount": 3,
            "partnerId": _vm.partnerId
          }
        }) : _c('span', [_vm._v("-")])];
      }
    }, {
      key: "item.number",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" #" + _vm._s(item.number) + " ")];
      }
    }, {
      key: "item.description",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('p', {
          staticStyle: {
            "max-width": "250px"
          }
        }, [_vm._v(" " + _vm._s(item.description) + " ")])];
      }
    }, {
      key: "item.userId",
      fn: function fn(_ref8) {
        var value = _ref8.value;
        return [value ? _c('assignee-preview', {
          attrs: {
            "value": [value],
            "amount": 1,
            "partnerId": _vm.partnerId
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.startDate",
      fn: function fn(_ref9) {
        var value = _ref9.value;
        return [value ? _c('v-chip', {
          attrs: {
            "outlined": "",
            "x-small": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "x-small": "",
            "left": ""
          }
        }, [_vm._v("mdi-calendar-start")]), _vm._v(" " + _vm._s(_vm.simpleDoubleDigitDate(value)) + " ")], 1) : _c('div')];
      }
    }, {
      key: "item.endDate",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [item.endDate ? _c('due-date-chip', {
          attrs: {
            "ticket": {
              due: item.endDate
            }
          }
        }) : _c('div')];
      }
    }, {
      key: "item.type",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('v-chip', {
          attrs: {
            "color": "info",
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("enums.ContractTypeEnum.".concat(item.type))) + " ")])];
      }
    }, {
      key: "item.documentIds",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_vm.toArray(item.documentIds).length ? _c('refs-selected', {
          staticStyle: {
            "max-width": "250px"
          },
          attrs: {
            "value": item.documentIds.map(function (d) {
              return {
                refId: d,
                refType: _vm.BackendResourceEnum.DOCUMENT
              };
            }),
            "confirmable": false,
            "draggable": false,
            "removable": false,
            "small": true,
            "partnerId": item.partnerId,
            "outlined": false
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.providerIds.refId",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_vm.toArray(item.providerIds).length ? _c('refs-selected', {
          staticStyle: {
            "max-width": "250px"
          },
          attrs: {
            "value": _vm.toArray(item.providerIds),
            "confirmable": false,
            "draggable": false,
            "removable": false,
            "small": true,
            "partnerId": item.partnerId,
            "outlined": false
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.clientIds",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_vm.toArray(item.clientIds).length ? _c('refs-selected', {
          staticStyle: {
            "max-width": "250px"
          },
          attrs: {
            "value": _vm.toArray(item.clientIds),
            "confirmable": false,
            "draggable": false,
            "removable": false,
            "small": true,
            "partnerId": item.partnerId,
            "outlined": false
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.id",
      fn: function fn(_ref15) {
        var item = _ref15.item,
            value = _ref15.value;
        return [value ? _c('refs-selected', {
          staticStyle: {
            "max-width": "250px"
          },
          attrs: {
            "value": [{
              refId: value,
              refType: _vm.type
            }],
            "confirmable": false,
            "draggable": false,
            "removable": false,
            "small": true,
            "partnerId": item.partnerId,
            "outlined": false
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.refs.refType",
      fn: function fn(_ref16) {
        var value = _ref16.value;
        return _vm._l(Array.from(new Set(_vm.toArray(value))), function (refType) {
          return _c('v-chip', {
            key: 'v-chip-' + refType + '-' + Math.random(),
            attrs: {
              "color": "info",
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("BackendResourceEnum.".concat(refType))) + " ")]);
        });
      }
    }, {
      key: "item.refs.refId",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [item.refs ? _c('refs-selected', {
          staticStyle: {
            "max-width": "250px"
          },
          attrs: {
            "value": _vm.toArray(item.refs),
            "confirmable": false,
            "draggable": false,
            "removable": false,
            "small": true,
            "partnerId": item.partnerId,
            "outlined": false
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.refs",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [item.refs ? _c('refs-selected', {
          staticStyle: {
            "max-width": "250px"
          },
          attrs: {
            "value": _vm.toArray(item.refs),
            "confirmable": false,
            "draggable": false,
            "removable": false,
            "small": true,
            "partnerId": item.partnerId,
            "outlined": false
          }
        }) : _vm._e()];
      }
    }])
  })], 1) : _c('latest-entries-card-empty'), _vm.selectedItem ? _c('group-type-entity-side-card', {
    attrs: {
      "value": _vm.selectedItem,
      "type": _vm.type
    },
    on: {
      "close": _vm.closeSideCard
    }
  }) : _vm._e(), _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.CREATE,
      "a": _vm.ResourceEnum.COMPANY,
      "field": _vm.partnerId
    }
  }, [_c('group-type-entity-create-dialog', {
    ref: "groupTypeEntityCreateDialog",
    attrs: {
      "hideButton": true,
      "partnerId": _vm.partnerId,
      "groupId": _vm.groupId,
      "type": _vm.type
    },
    on: {
      "created": _vm.openSideCard
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }