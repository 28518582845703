var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-container', [_c('partner-user-detail', {
    attrs: {
      "partnerId": _vm.partnerId,
      "loading": _vm.isLoadingDetail
    },
    model: {
      value: _vm.user,
      callback: function callback($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }