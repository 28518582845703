var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('partner-manage-card', {
    attrs: {
      "partnerId": _vm.partnerId
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }