var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "mr-2"
  }, [_vm.isCsvExport ? _c('excel-import-update', {
    attrs: {
      "dto": _vm.updateDto,
      "outlined": _vm.outlined,
      "small": _vm.small,
      "text": _vm.$t('common.nouns.update')
    },
    on: {
      "updated": _vm.onUpdated
    }
  }) : _vm._e()], 1), _c('span', [_c('excel-import-create', {
    attrs: {
      "dto": _vm.vehicleDto,
      "baseData": {
        partnerId: _vm.partnerId
      },
      "outlined": _vm.outlined,
      "small": _vm.small,
      "text": _vm.$t('partner.excelimport.btnText')
    },
    on: {
      "created": _vm.onUpdated
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }