var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Can', {
    attrs: {
      "I": _vm.ActionEnum.CREATE,
      "a": _vm.ResourceEnum.WEBHOOK_CONFIG,
      "field": _vm.partnerId
    }
  }, [!_vm.hideButton ? _vm._t("activator", function () {
    return [_c('v-btn', {
      attrs: {
        "elevation": 0
      },
      on: {
        "click": _vm.show
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")])];
  }, {
    "on": _vm.show
  }) : _vm._e()], 2), _vm.isDialogActiveLocal ? _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.$t('webhook.createWebhookConfig'),
      "isDialogActive": _vm.isDialogActiveLocal,
      "rightText": _vm.$t('create'),
      "loading": _vm.isLoading,
      "rightLoading": _vm.isLoading,
      "rightDisabled": !_vm.isFormValid,
      "supressKeyboardConfirm": true
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActiveLocal = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActiveLocal = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDialogActive = false;
      },
      "close": function close($event) {
        _vm.isDialogActive = false;
      },
      "rightClick": _vm.onRightClick
    }
  }, [_c('v-row', {
    staticClass: "pt-3",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-form', {
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('objects.webhookConfig.title'),
      "rules": _vm.requiredRule,
      "id": "titleInput"
    },
    model: {
      value: _vm.webhookConfig.title,
      callback: function callback($$v) {
        _vm.$set(_vm.webhookConfig, "title", $$v);
      },
      expression: "webhookConfig.title"
    }
  }), _c('v-textarea', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('objects.webhookConfig.description')
    },
    model: {
      value: _vm.webhookConfig.description,
      callback: function callback($$v) {
        _vm.$set(_vm.webhookConfig, "description", $$v);
      },
      expression: "webhookConfig.description"
    }
  }), _c('custom-field-boolean', {
    attrs: {
      "customField": {
        label: _vm.$t('objects.webhookConfig.isActive')
      }
    },
    model: {
      value: _vm.webhookConfig.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.webhookConfig, "isActive", $$v);
      },
      expression: "webhookConfig.isActive"
    }
  }), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('objects.webhookConfig.url'),
      "rules": _vm.requiredRule
    },
    model: {
      value: _vm.webhookConfig.url,
      callback: function callback($$v) {
        _vm.$set(_vm.webhookConfig, "url", $$v);
      },
      expression: "webhookConfig.url"
    }
  }), _c('webhook-type-selection', {
    attrs: {
      "resource": _vm.webhookConfig.resource,
      "action": _vm.webhookConfig.action,
      "type": _vm.webhookConfig.type
    },
    on: {
      "update:resource": function updateResource($event) {
        return _vm.$set(_vm.webhookConfig, "resource", $event);
      },
      "update:action": function updateAction($event) {
        return _vm.$set(_vm.webhookConfig, "action", $event);
      },
      "update:type": function updateType($event) {
        return _vm.$set(_vm.webhookConfig, "type", $event);
      }
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }