var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Can', {
    attrs: {
      "I": _vm.ActionEnum.UPDATE,
      "a": _vm.ResourceEnum.CONTRACT,
      "field": _vm.partnerId
    }
  }, [!_vm.hideButton ? _c('v-btn', {
    attrs: {
      "elevation": 0
    },
    on: {
      "click": _vm.open
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]) : _vm._e()], 1), _vm.isDialogActive ? _c('confirm-action-dialog', {
    attrs: {
      "width": "1050",
      "persistent": true,
      "supressKeyboardActions": true,
      "title": _vm.$t(_vm.title),
      "isDialogActive": _vm.isDialogActive,
      "leftText": _vm.leftText,
      "rightText": _vm.$t('add'),
      "loading": _vm.isLoading,
      "rightLoading": _vm.isLoading,
      "rightDisabled": !_vm.selectedEntity
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "leftClick": _vm.leftClick,
      "rightClick": _vm.addRefsToContract,
      "close": _vm.close
    }
  }, [!_vm.selectedEntity ? _c('refs-selection-by-type', {
    attrs: {
      "partnerId": _vm.partnerId,
      "refType": _vm.ResourceEnum.CONTRACT,
      "showAsMenu": false,
      "small": false,
      "predefinedFilter": _vm.predefinedFilter
    },
    on: {
      "select": function select($event) {
        _vm.selectedEntity = $event;
      }
    }
  }) : _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('refs-list', {
    attrs: {
      "item": _vm.selectedEntity,
      "refType": _vm.ResourceEnum.CONTRACT,
      "confirmable": false,
      "small": false
    }
  })], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }