var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.CREATE,
      "a": _vm.type,
      "field": _vm.partnerId
    }
  }, [_c(_vm.component, {
    ref: _vm.component,
    tag: "component",
    attrs: {
      "partnerId": _vm.partnerId,
      "groupId": _vm.groupId,
      "hideButton": _vm.hideButton
    },
    on: {
      "created": function created($event) {
        return _vm.$emit('created', $event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }