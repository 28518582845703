var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_vm.isLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  }) : _vm.companyGroup ? _c('div', [_c('m-header', {
    attrs: {
      "breadCrumbs": _vm.breadCrumbList,
      "title": _vm.companyGroup.title,
      "actions": _vm.actions,
      "viewId": _vm.viewIdLocal,
      "chips": _vm.chips
    },
    on: {
      "update:viewId": function updateViewId($event) {
        _vm.viewIdLocal = $event;
      },
      "update:view-id": function updateViewId($event) {
        _vm.viewIdLocal = $event;
      },
      "actionClicked": function actionClicked(action) {
        return action.exec();
      }
    }
  }), _c('custom-views', {
    ref: "customViews",
    attrs: {
      "store": _vm.store,
      "entity": _vm.companyGroup,
      "viewId": _vm.viewIdLocal,
      "refType": _vm.BackendResourceEnum.COMPANY,
      "boardBaseConfig": _vm.boardBaseConfig,
      "calendarBaseConfig": _vm.calendarBaseConfig,
      "tableBaseConfig": _vm.tableBaseConfig
    },
    on: {
      "update:viewId": function updateViewId($event) {
        _vm.viewIdLocal = $event;
      },
      "update:view-id": function updateViewId($event) {
        _vm.viewIdLocal = $event;
      },
      "openSideCard": _vm.openSideCard,
      "goToSettings": _vm.goToSettings
    },
    scopedSlots: _vm._u([{
      key: "item.groupId",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('refs-company-group', {
          attrs: {
            "itemAsync": _vm.getCompanyGroup(item.groupId),
            "small": true,
            "confirmable": false
          },
          on: {
            "openDetail": function openDetail($event) {
              return _vm.goToCompanyGroupDetail(item.groupId);
            }
          }
        })];
      }
    }, {
      key: "item.companyName",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.openSideCard(item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.companyName) + " ")])];
      }
    }, {
      key: "item.timestamp.created",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(item.timestamp.created)) + " ")];
      }
    }, {
      key: "item.userId",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [_c('assignee-preview', {
          attrs: {
            "value": [value],
            "partnerId": _vm.partnerId
          }
        })];
      }
    }, {
      key: "item.addresses.street",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [_c('div', {
          domProps: {
            "innerHTML": _vm._s(Array.from(new Set(_vm.toArray(value))).join('<br/>'))
          }
        })];
      }
    }, {
      key: "item.addresses.zip",
      fn: function fn(_ref6) {
        var value = _ref6.value;
        return [_c('div', {
          domProps: {
            "innerHTML": _vm._s(Array.from(new Set(_vm.toArray(value))).join('<br/>'))
          }
        })];
      }
    }, {
      key: "item.addresses.city",
      fn: function fn(_ref7) {
        var value = _ref7.value;
        return [_c('div', {
          domProps: {
            "innerHTML": _vm._s(Array.from(new Set(_vm.toArray(value))).join('<br/>'))
          }
        })];
      }
    }, {
      key: "item.addresses.state",
      fn: function fn(_ref8) {
        var value = _ref8.value;
        return [_c('div', {
          domProps: {
            "innerHTML": _vm._s(Array.from(new Set(_vm.toArray(value))).join('<br/>'))
          }
        })];
      }
    }, {
      key: "item.addresses.countryCode",
      fn: function fn(_ref9) {
        var value = _ref9.value;
        return [_c('div', {
          domProps: {
            "innerHTML": _vm._s(Array.from(new Set(_vm.toArray(value))).join('<br/>'))
          }
        })];
      }
    }, {
      key: "item.addresses.isBilling",
      fn: function fn(_ref10) {
        var value = _ref10.value;
        return [Array.from(new Set(_vm.toArray(value))).includes(true) ? _c('v-chip', {
          attrs: {
            "x-small": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("objects.company.addresses.isBilling")) + " ")]) : _c('div')];
      }
    }, {
      key: "item.services",
      fn: function fn(_ref11) {
        var value = _ref11.value,
            item = _ref11.item;
        return _vm._l(_vm.toArray(value), function (type) {
          return _c('v-chip', {
            key: type + item.id,
            staticClass: "ma-1",
            attrs: {
              "x-small": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("enums.CompanyServiceEnum.".concat(type))) + " ")]);
        });
      }
    }])
  })], 1) : _c('latest-entries-card-empty'), _vm.selectedItem ? _c('company-side-card', {
    attrs: {
      "value": _vm.selectedItem
    },
    on: {
      "close": _vm.closeSideCard
    }
  }) : _vm._e(), _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.CREATE,
      "a": _vm.ResourceEnum.COMPANY,
      "field": _vm.partnerId
    }
  }, [_c('company-create-dialog', {
    ref: "companyDialog",
    attrs: {
      "hideButton": true,
      "partnerId": _vm.partnerId,
      "groupId": _vm.companyGroupId
    },
    on: {
      "created": _vm.openSideCard
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }