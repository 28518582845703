var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('m-detail-table-formable', {
    ref: "table",
    attrs: {
      "title": _vm.$t('about'),
      "item": _vm.value,
      "formable": _vm.formable,
      "outlined": true,
      "omit": ['id', 'company', 'partnerId', '.lastModified', 'values', 'assignees', 'groupId', 'description'],
      "showCustomFields": false,
      "onDetail": _vm.onDetail
    },
    scopedSlots: _vm._u([{
      key: "item.deliveryAddresses",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_c('v-hover', {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hover = _ref2.hover;
              return [_c('div', {
                staticStyle: {
                  "display": "flex",
                  "align-items": "center"
                }
              }, [_c('td', [_c('refs-company-address', {
                attrs: {
                  "item": value
                }
              })], 1), _c('td', [hover ? _c('v-btn', {
                attrs: {
                  "icon": "",
                  "small": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return function () {
                      return value.copyToClipboard();
                    }.apply(null, arguments);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-content-copy")])], 1) : _vm._e()], 1)])];
            }
          }], null, true)
        })];
      }
    }, {
      key: "item.billingAddresses",
      fn: function fn(_ref3) {
        var value = _ref3.value;
        return [_c('v-hover', {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var hover = _ref4.hover;
              return [_c('div', {
                staticStyle: {
                  "display": "flex",
                  "align-items": "center"
                }
              }, [_c('td', [_c('refs-company-address', {
                attrs: {
                  "item": value
                }
              })], 1), _c('td', [hover ? _c('v-btn', {
                attrs: {
                  "icon": "",
                  "small": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return function () {
                      return value.copyToClipboard();
                    }.apply(null, arguments);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-content-copy")])], 1) : _vm._e()], 1)])];
            }
          }], null, true)
        })];
      }
    }, {
      key: "additionalTableRows",
      fn: function fn() {
        return [_c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("common.nouns.billingAddress")))]), _c('td', {
          attrs: {
            "colspan": "2"
          }
        }, [_c('refs-company-address', {
          attrs: {
            "small": true,
            "item": _vm.value.billingAddress
          }
        })], 1)]), _vm._t("additionalTableRows")];
      },
      proxy: true
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }