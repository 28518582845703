var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('m-header', {
    attrs: {
      "title": _vm.value.title,
      "breadCrumbs": _vm.breadCrumbs,
      "actions": _vm.isEditingTitle ? [] : _vm.actions
    },
    on: {
      "actionClicked": function actionClicked($event) {
        return $event.exec();
      },
      "alertClicked": function alertClicked($event) {
        return $event.exec();
      }
    },
    scopedSlots: _vm._u([_vm.isEditingTitle ? {
      key: "title",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mt-1 mb-n1",
          attrs: {
            "label": _vm.$t('common.nouns.title'),
            "outlined": "",
            "loading": _vm.isLoadingTitle,
            "readonly": _vm.isLoadingTitle
          },
          model: {
            value: _vm.editTitleCopy,
            callback: function callback($$v) {
              _vm.editTitleCopy = $$v;
            },
            expression: "editTitleCopy"
          }
        }), _c('div', {
          staticClass: "mt-n6"
        }, [_c('v-btn', {
          staticClass: "mr-1",
          attrs: {
            "x-small": "",
            "elevation": 0,
            "color": "info"
          },
          on: {
            "click": _vm.saveEditTitle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]), _c('v-btn', {
          attrs: {
            "x-small": "",
            "elevation": 0,
            "color": "info",
            "outlined": ""
          },
          on: {
            "click": _vm.cancelEditTitle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")])], 1)];
      },
      proxy: true
    } : null], null, true)
  }), _c('v-divider'), _c('m-detail-view-grid', {
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('m-action-list', {
          attrs: {
            "divider": false,
            "actions": _vm.detailActions
          },
          on: {
            "actionClicked": _vm.processAction
          }
        })];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "mt-3"
  }, [_c('div', {
    staticClass: "mx-2",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('small', {
    staticClass: "ml-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("createdOn", {
    date: _vm.createdDate
  })))]), _c('v-spacer'), !_vm.isEditDescription ? _c('v-btn', {
    attrs: {
      "elevation": 0,
      "text": "",
      "x-small": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('edit'))
    },
    on: {
      "click": _vm.startEditDescription
    }
  }) : _vm._e()], 1), _c('v-textarea', {
    staticClass: "mx-2",
    staticStyle: {
      "height": "180px"
    },
    attrs: {
      "loading": _vm.isLoadingDescription,
      "readonly": !_vm.isEditDescription || _vm.isLoadingDescription,
      "outlined": true
    },
    model: {
      value: _vm.descriptionCopy,
      callback: function callback($$v) {
        _vm.descriptionCopy = $$v;
      },
      expression: "descriptionCopy"
    }
  }), _vm.isEditDescription ? _c('div', {
    staticClass: "mt-n2 mx-2",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "elevation": 0,
      "small": "",
      "color": "info"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('save'))
    },
    on: {
      "click": _vm.saveEditDescription
    }
  }), _c('v-btn', {
    attrs: {
      "elevation": 0,
      "small": "",
      "outlined": "",
      "color": "info"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('close'))
    },
    on: {
      "click": _vm.abortEditDesciption
    }
  })], 1) : _vm._e()], 1), _c('v-card', {
    staticClass: "mt-3",
    attrs: {
      "flat": "",
      "loading": _vm.isLoadingCustomFields
    }
  }, [_c('div', {
    staticClass: "mx-2",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('small', {
    staticClass: "ml-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("views.CustomFieldList.customFields")) + " ")]), _c('v-spacer'), !_vm.isEditCustomFields ? _c('v-btn', {
    staticClass: "mb-n2",
    attrs: {
      "x-small": "",
      "elevation": 0,
      "text": ""
    },
    on: {
      "click": _vm.startEditCustomFields
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]) : _vm._e()], 1), !_vm.isEditCustomFields && !_vm.value.configuration.customFieldConfig.length ? _c('v-card-subtitle', {
    staticClass: "mx-n1"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('person.selectCustomFieldProjectExplanationI'))
    }
  }), _c('a', {
    on: {
      "click": _vm.goToCustomFields
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('person.selectCustomFieldProjectExplanationII'))
    }
  })]), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('person.selectCustomFieldProjectExplanationIII'))
    }
  })]) : !_vm.isEditCustomFields ? _c('v-card-text', [_c('custom-field-configuration-form', {
    staticClass: "mx-n1",
    attrs: {
      "disabled": !_vm.isEditCustomFields
    },
    model: {
      value: _vm.value.configuration.customFieldConfig,
      callback: function callback($$v) {
        _vm.$set(_vm.value.configuration, "customFieldConfig", $$v);
      },
      expression: "value.configuration.customFieldConfig"
    }
  })], 1) : _c('v-card-text', [_c('custom-field-configuration-form', {
    staticClass: "mx-n1",
    attrs: {
      "disabled": _vm.isLoadingCustomFields
    },
    model: {
      value: _vm.customFieldConfigCopy,
      callback: function callback($$v) {
        _vm.customFieldConfigCopy = $$v;
      },
      expression: "customFieldConfigCopy"
    }
  })], 1), _vm.isEditCustomFields && !_vm.isLoadingCustomFields ? _c('v-card-actions', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-btn', {
    attrs: {
      "elevation": 0,
      "small": "",
      "color": "info"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('save'))
    },
    on: {
      "click": _vm.saveCustomFields
    }
  }), _c('v-btn', {
    attrs: {
      "elevation": 0,
      "small": "",
      "outlined": "",
      "color": "info"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('close'))
    },
    on: {
      "click": _vm.abortEditCustomFields
    }
  })], 1) : _vm._e()], 1)], 1), _vm.isDeleteDialogActive && _vm.value ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "title": _vm.$t('partner.PartnerReportDetailChangeProgress.confirmDeleteTitle'),
      "rightColor": 'error'
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isDeleteDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.onDelete
    }
  }, [_c('refs-person', {
    attrs: {
      "item": _vm.value
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }