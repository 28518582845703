var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('person-import-card', {
    attrs: {
      "partnerId": _vm.partnerId
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }