var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('paginated-table', {
    attrs: {
      "store": _vm.store,
      "headers": _vm.headers,
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "controlElements": _vm.controlElements,
      "predefinedFilter": _vm.predefinedFilter,
      "groupDesc": true,
      "sortBy": 'timestamp.created',
      "sortDesc": true,
      "selectedItems": _vm.selectedItemsLocal
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selectedItemsLocal = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selectedItemsLocal = $event;
      },
      "click:row": _vm.setSelectedItem
    },
    scopedSlots: _vm._u([{
      key: "item.system",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-chip', {
          attrs: {
            "color": item.system === _vm.TpsEnum.DA ? 'info' : 'success'
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("enums.TpsEnum.".concat(item.system))) + " ")])];
      }
    }, {
      key: "item.refs",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('refs-selected', {
          attrs: {
            "value": item.refs,
            "partnerId": _vm.partnerId,
            "small": true,
            "outlined": false,
            "draggable": false,
            "removable": false
          }
        })];
      }
    }, {
      key: "item.userId",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('assignee-preview', {
          attrs: {
            "value": [item.userId],
            "partnerId": _vm.partnerId
          }
        })];
      }
    }, {
      key: "item.timestamp.created",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.timestamp.createdReadable) + " ")];
      }
    }])
  }), _vm.selectedItem ? _c('third-party-system-side-card', {
    attrs: {
      "value": _vm.selectedItem
    },
    on: {
      "close": function close() {
        return _vm.setSelectedItem(null);
      }
    }
  }) : _vm._e(), _vm.isDeleteDialogActive && _vm.selectedItemForDelete ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "title": _vm.$t('partner.PartnerReportDetailChangeProgress.confirmDeleteTitle'),
      "rightColor": 'error',
      "rightLoading": _vm.isDeleteLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isDeleteDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.onDelete
    }
  }, [_c('refs-third-party-system', {
    attrs: {
      "item": _vm.selectedItemForDelete
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }