var render = function () {
  var _vm$active2, _vm$active3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-tabs', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tabs-slider', {
    attrs: {
      "color": "info"
    }
  }), _vm._l(Object.values(_vm.FleetTabEnum), function (tab) {
    return _c('v-tab', {
      key: 'v-tab' + tab
    }, [_vm._v(" " + _vm._s(_vm.$t("enums.FleetTabEnum.".concat(tab))) + " ")]);
  })], 2), _c('v-divider'), _vm.active ? _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    key: _vm.FleetTabEnum.OVERVIEW
  }, [_c('m-detail-view-grid', {
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        var _vm$active;

        return [_c('div', {
          staticClass: "mx-4"
        }, [_c('select-assignees', {
          staticClass: "mt-6",
          attrs: {
            "css": "width: 100%",
            "partnerId": _vm.partnerId,
            "outlined": true,
            "dense": false
          },
          on: {
            "input": _vm.onAssigneesUpdate
          },
          model: {
            value: _vm.active.assignees,
            callback: function callback($$v) {
              _vm.$set(_vm.active, "assignees", $$v);
            },
            expression: "active.assignees"
          }
        }), _c('v-card', {
          staticClass: "my-2 mb-1",
          attrs: {
            "outlined": "",
            "elevation": 0
          }
        }, [(_vm$active = _vm.active) !== null && _vm$active !== void 0 && _vm$active._isLoadingCompany ? _c('v-skeleton-loader', {
          staticClass: "mx-auto",
          attrs: {
            "type": "list-item-two-line"
          }
        }) : _vm.active.companyId && _vm.active.company ? _c('refs-company', {
          attrs: {
            "item": _vm.active.company
          }
        }) : _c('v-list-item', {
          on: {
            "click": _vm.openCompanyDialog
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-office-building")])], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetDetailView.addCompany")) + " ")])], 1)], 1), _c('m-action-list', {
          attrs: {
            "divider": true,
            "actions": _vm.actions
          },
          on: {
            "actionClicked": function actionClicked($event) {
              return $event.exec();
            }
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 2556645227)
  }, [_c('has-description', {
    key: _vm.active.id,
    staticClass: "mx-2",
    attrs: {
      "isPlainText": false,
      "height": "500px"
    },
    model: {
      value: _vm.active,
      callback: function callback($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  })], 1)], 1), _c('v-tab-item', {
    key: _vm.FleetTabEnum.VEHICLES
  }, [_vm.active ? _c('fleet-vehicle-table', {
    key: _vm.active.id,
    attrs: {
      "filters": _vm.fleetTableFilters,
      "groupId": _vm.active.id
    }
  }) : _vm._e()], 1), _c('v-tab-item', {
    key: _vm.FleetTabEnum.PEOPLE_TABLE
  }, [_vm.active ? _c('person-table', {
    key: _vm.active.id,
    attrs: {
      "filters": _vm.referenceTableFilters,
      "partnerId": _vm.partnerId,
      "controlElements": _vm.personTableControlElements,
      "refs": _vm.createRefs
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [_c('select-by-refs-update-dialog', {
          staticClass: "ml-2",
          attrs: {
            "partnerId": _vm.partnerId,
            "refs": _vm.createRefs,
            "refType": _vm.ResourceEnum.PERSON
          }
        })];
      },
      proxy: true
    }], null, false, 582511648)
  }) : _vm._e()], 1), _c('v-tab-item', {
    key: _vm.FleetTabEnum.COMPANY_TABLE
  }, [_vm.active ? _c('company-table', {
    key: _vm.active.id,
    attrs: {
      "filters": _vm.referenceTableFilters,
      "partnerId": _vm.partnerId,
      "controlElements": _vm.personTableControlElements,
      "refs": _vm.createRefs,
      "showIsFleetColumn": false
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [_c('select-by-refs-update-dialog', {
          staticClass: "ml-2",
          attrs: {
            "partnerId": _vm.partnerId,
            "refs": _vm.createRefs,
            "predefinedFilter": _vm.companySelectPredefinedFilter,
            "refType": _vm.ResourceEnum.COMPANY,
            "title": 'company.addCompany'
          }
        })];
      },
      proxy: true
    }], null, false, 83034866)
  }) : _vm._e()], 1), _c('v-tab-item', {
    key: _vm.FleetTabEnum.CONTRACT_TABLE
  }, [_vm.active ? _c('contract-table', {
    key: _vm.active.id,
    attrs: {
      "filters": _vm.referenceTableFilters,
      "partnerId": _vm.partnerId,
      "controlElements": _vm.personTableControlElements,
      "refs": _vm.createRefs,
      "showIsFleetColumn": false
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [_c('select-by-refs-update-dialog', {
          staticClass: "ml-2",
          attrs: {
            "partnerId": _vm.partnerId,
            "refs": _vm.createRefs,
            "title": 'contract.addContract',
            "refType": _vm.ResourceEnum.CONTRACT
          }
        })];
      },
      proxy: true
    }], null, false, 3996963198)
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.active && _vm.can(_vm.ActionEnum.CREATE, _vm.ResourceEnum.FLEET) ? _c('create-fleet-dialog', {
    ref: "createFleetDialog",
    attrs: {
      "partnerId": _vm.partnerId,
      "parentId": _vm.active.id
    }
  }) : _vm._e(), _vm.active ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "rightLoading": _vm.isLoadingDelete,
      "loading": _vm.isLoadingDelete,
      "hideLeft": true
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "rightClick": _vm.deleteFleet
    }
  }) : _vm._e(), _vm.active ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isCompanyDialogActive,
      "rightLoading": (_vm$active2 = _vm.active) === null || _vm$active2 === void 0 ? void 0 : _vm$active2._isLoadingCompany,
      "rightDisabled": _vm.isCompanyLoading,
      "hideLeft": true
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isCompanyDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isCompanyDialogActive = $event;
      },
      "rightClick": _vm.saveCompany
    }
  }, [_c('v-form', [_c('v-autocomplete', {
    ref: "companySelectField",
    attrs: {
      "loading": _vm.isCompanyLoading,
      "disabled": (_vm$active3 = _vm.active) === null || _vm$active3 === void 0 ? void 0 : _vm$active3._isLoadingCompany,
      "items": _vm.companies,
      "outlined": "",
      "label": _vm.$t('objects.fleet.company'),
      "item-text": "companyName",
      "item-value": "id",
      "append-outer-icon": _vm.showLoadMore ? 'mdi-refresh' : '',
      "clearable": ""
    },
    on: {
      "click:append-outer": _vm.loadMoreCompanies
    },
    model: {
      value: _vm.newCompanyId,
      callback: function callback($$v) {
        _vm.newCompanyId = $$v;
      },
      expression: "newCompanyId"
    }
  })], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }