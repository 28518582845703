var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ma-2"
  }, [_c('calendar', {
    attrs: {
      "id": !_vm.isVehicleId ? 'fleetCalendarCardId' : '',
      "headers": _vm.headers,
      "events": _vm.filteredEvents,
      "loading": _vm.loading,
      "type": _vm.calendarType,
      "groupBy": _vm.groupBy
    },
    on: {
      "update:type": function updateType($event) {
        _vm.calendarType = $event;
      },
      "click:row": _vm.selectEvent,
      "click:detail": _vm.selectEvent,
      "contextmenu:event": _vm.openEventSideCard
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [_vm.enableGrouping ? _c('v-checkbox', {
          attrs: {
            "input-value": !!_vm.groupBy,
            "label": _vm.$t('views.fleet.FleetVehicleDetailEventCard.groupByVehicle'),
            "value": ""
          },
          on: {
            "click": _vm.toggleGroupBy
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "group.header",
      fn: function fn(_ref) {
        var groupBy = _ref.groupBy,
            group = _ref.group,
            toggle = _ref.toggle,
            isOpen = _ref.isOpen,
            items = _ref.items,
            headers = _ref.headers;
        return [_vm._t("default", function () {
          return [groupBy[0] === _vm.GROUP_BY_VEHICLE ? _c('th', {
            attrs: {
              "colspan": headers.length
            }
          }, [_c('div', {
            staticStyle: {
              "display": "flex",
              "align-items": "center",
              "justify-content": "center"
            }
          }, [_c('v-btn', {
            attrs: {
              "icon": ""
            },
            on: {
              "click": toggle
            }
          }, [_c('v-icon', [_vm._v(" " + _vm._s(isOpen ? "mdi-chevron-down" : "mdi-chevron-right") + " ")])], 1), _c('span', [_vm.getVehicleForId(group) ? _c('refs-vehicle', {
            attrs: {
              "item": _vm.getVehicleForId(group)
            },
            on: {
              "openDetail": _vm.openVehicleDetail
            }
          }) : _c('span', [_c('v-icon', [_vm._v("mdi-car")]), _vm._v(" " + _vm._s(group) + " ")], 1)], 1), _c('v-spacer'), _c('span', [_c('v-chip', {
            attrs: {
              "small": ""
            }
          }, [_vm._v(_vm._s(items.length) + " # ")])], 1)], 1)]) : _vm._e()];
        }, {
          "groupBy": groupBy,
          "group": group,
          "toggle": toggle,
          "isOpen": isOpen,
          "items": items
        })];
      }
    }, {
      key: "event",
      fn: function fn(_ref2) {
        var event = _ref2.event;
        return [_c('div', [_c('div', {
          staticStyle: {
            "display": "flex",
            "width": "100%"
          }
        }, [_c('div', {
          staticStyle: {
            "width": "calc(100% - 10px)",
            "overflow": "hidden"
          }
        }, [_vm._v(" " + _vm._s(event.name) + " ")]), _c('tooltip', {
          attrs: {
            "text": _vm.getEventDisplayConfig(event).text
          }
        }, [_c('v-icon', {
          attrs: {
            "x-small": "",
            "color": "white"
          }
        }, [_vm._v(" " + _vm._s(_vm.getEventDisplayConfig(event).icon) + " ")])], 1)], 1), _c('div', {
          staticClass: "mt-n1",
          staticStyle: {
            "display": "flex"
          },
          attrs: {
            "justify": "center"
          }
        }, [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "white"
          }
        }, [_vm._v("mdi-car")]), _vm._v(" " + _vm._s(event.vehicle.displayName) + " ")], 1)])];
      }
    }, {
      key: "preview",
      fn: function fn(_ref3) {
        var slotScope = _ref3.slotScope;
        return [slotScope.item ? _c('v-card', {
          staticStyle: {
            "min-width": "350px"
          }
        }, [_c('v-card-title', [_c('span', {
          staticClass: "text-truncate",
          staticStyle: {
            "max-width": "290px"
          }
        }, [_vm._v(" " + _vm._s(slotScope.item.name) + " ")]), _c('v-spacer'), slotScope.item.type === _vm.VehicleEventTypeEnum.EVENT || slotScope.item.type === _vm.VehicleEventTypeEnum.LEASING_START || slotScope.item.type === _vm.VehicleEventTypeEnum.LEASING_END ? _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": slotScope.open
          }
        }, [_c('v-icon', [_vm._v(" mdi-arrow-expand ")])], 1) : _vm._e(), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return slotScope.close();
            }
          }
        }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)], 1), _c('v-card-text', [_c('v-list', [_c('fleet-vehicle-detail-event-card-calendar-detail', {
          attrs: {
            "value": slotScope.item,
            "showVehicle": !_vm.isVehicleId
          },
          on: {
            "openVehicleDetail": function openVehicleDetail(v) {
              return _vm.openVehicleDetail(v, slotScope.close);
            },
            "openTicketDetail": function openTicketDetail(v) {
              return _vm.openTicketDetail(v, slotScope.close);
            },
            "openReportDetail": function openReportDetail(v) {
              return _vm.openReportDetail(v, slotScope.close);
            },
            "openContractDetail": function openContractDetail(v) {
              return _vm.openContractDetail(v, slotScope.item.vehicle.id, slotScope.close);
            },
            "acknowledged": function acknowledged($event) {
              return _vm.$emit('updateEventList', _vm.calendarFocus);
            }
          }
        })], 1)], 1)], 1) : _c('v-card', [_c('v-skeleton-loader', {
          attrs: {
            "type": "card"
          }
        })], 1)];
      }
    }, {
      key: "titleAction",
      fn: function fn() {
        return [_c('event-create-dialog', {
          attrs: {
            "vehicleId": _vm.currentVehicleId,
            "showVehicleSelection": !_vm.isVehicleId,
            "createEvent": _vm.onCreateEvent
          },
          on: {
            "update:vehicleId": function updateVehicleId($event) {
              _vm.currentVehicleId = $event;
            },
            "update:vehicle-id": function updateVehicleId($event) {
              _vm.currentVehicleId = $event;
            }
          }
        }), _c('div', [_c('span', {
          staticClass: "mr-2"
        }), _c('v-btn', {
          attrs: {
            "small": "",
            "outlined": "",
            "color": "info"
          },
          on: {
            "click": _vm.goToPartnerEvents
          }
        }, [_vm._v(_vm._s(_vm.$t("views.fleet.actions.goToEvents")))])], 1), _c('span', {
          staticClass: "mr-2"
        })];
      },
      proxy: true
    }, {
      key: "contextMenuAction",
      fn: function fn() {
        return [_c('context-menu', {
          attrs: {
            "closeOnContentClick": false
          }
        }, _vm._l(Object.keys(_vm.filters), function (cat) {
          return _c('v-list', {
            key: cat + 'purrrr',
            attrs: {
              "dense": ""
            }
          }, [_c('v-list-item', [_c('v-list-item-avatar', [_c('v-avatar', {
            attrs: {
              "color": _vm.appointmentTypeColorsMap.get(cat),
              "size": "15px"
            }
          })], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("VehicleEventTypeEnum.".concat(cat))) + " ")]), _c('v-list-item-action', [_c('v-checkbox', {
            model: {
              value: _vm.filters[cat],
              callback: function callback($$v) {
                _vm.$set(_vm.filters, cat, $$v);
              },
              expression: "filters[cat]"
            }
          })], 1)], 1)], 1);
        }), 1)];
      },
      proxy: true
    }, {
      key: "item.type",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('tooltip', {
          attrs: {
            "text": _vm.getEventDisplayConfig(item).text
          }
        }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.getEventDisplayConfig(item).icon) + " ")])], 1)];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "text-wrap",
          style: "width: ".concat(_vm.getTableNameColumnWidth, ";")
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item.start",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.timed ? _c('span', [_vm._v(" " + _vm._s(_vm._f("formatHoursAndMinutes")(new Date(item.start))) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(new Date(item.start))) + " ")];
      }
    }, {
      key: "item.vehicle",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('refs-vehicle', {
          ref: 'vehicle' + item.vehicle.id,
          attrs: {
            "item": item.vehicle
          },
          on: {
            "openDetail": _vm.openVehicleDetail
          }
        })];
      }
    }], null, true),
    model: {
      value: _vm.calendarFocus,
      callback: function callback($$v) {
        _vm.calendarFocus = $$v;
      },
      expression: "calendarFocus"
    }
  }), _vm.selectedEvent && (_vm.selectedEvent.type === _vm.VehicleEventTypeEnum.LEASING_START || _vm.selectedEvent.type === _vm.VehicleEventTypeEnum.LEASING_END) ? _c('contract-side-card', {
    attrs: {
      "loading": _vm.loading || _vm.isUpdatingContractLoading,
      "partnerId": _vm.partnerId,
      "vehicleId": _vm.selectedEvent.vehicleId,
      "documentSuggestions": [],
      "loadingDocumentSuggestions": false
    },
    on: {
      "close": function close($event) {
        _vm.selectedEvent = null;
      },
      "save": _vm.onUpdateContract,
      "delete": _vm.onRemoveContract
    },
    model: {
      value: _vm.selectedEvent.data[0],
      callback: function callback($$v) {
        _vm.$set(_vm.selectedEvent.data, 0, $$v);
      },
      expression: "selectedEvent.data[0]"
    }
  }) : _vm._e(), _vm.recurringForDeletion ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isRecurringDeleteDialogActive,
      "max-width": "800",
      "title": _vm.$t('components.dialog.DeleteDialog.title'),
      "fullscreen": _vm.$vuetify.breakpoint.xsOnly,
      "rightLoading": _vm.loading,
      "rightDisabled": !_vm.reason
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isRecurringDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isRecurringDeleteDialogActive = $event;
      },
      "rightClick": function rightClick($event) {
        _vm.onDeleteEvent(_vm.recurringForDeletion, function () {
          return _vm.isRecurringDeleteDialogActive = false;
        });
      },
      "leftClick": function leftClick($event) {
        _vm.isRecurringDeleteDialogActive = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.dialog.DeleteDialog.reason")) + " "), _c('v-text-field', {
    staticClass: "mt-2",
    attrs: {
      "type": "text",
      "label": _vm.$t('components.dialog.DeleteDialog.reasonTextField'),
      "rules": _vm.requiredRule,
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.reason,
      callback: function callback($$v) {
        _vm.reason = $$v;
      },
      expression: "reason"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }