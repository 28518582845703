var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    staticClass: "ma-4",
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('debug', {
    attrs: {
      "debug": _vm.value
    }
  }), _vm.showVehicleSelection ? _c('v-row', [_c('v-col', {
    staticClass: "mb-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('select-entity', {
    attrs: {
      "refType": _vm.ResourceEnum.VEHICLE,
      "partnerId": _vm.partnerId,
      "label": _vm.$t('common.nouns.vehicle'),
      "clearable": true,
      "required": true
    },
    model: {
      value: _vm.selectedVehicle,
      callback: function callback($$v) {
        _vm.selectedVehicle = $$v;
      },
      expression: "selectedVehicle"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-4",
    attrs: {
      "label": _vm.$t('views.fleet.EventCreateDialog.summary'),
      "rules": _vm.requiredRule,
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.value.summary,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "summary", $$v);
      },
      expression: "value.summary"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "date",
      "outlined": "",
      "required": "",
      "label": _vm.$t('views.fleet.EventCreateDialog.start')
    },
    model: {
      value: _vm.value.startString,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "startString", $$v);
      },
      expression: "value.startString"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_vm.value.isVirtual || _vm.value.recurringEventId ? _c('custom-field-boolean', {
    attrs: {
      "value": true,
      "customField": {
        label: _vm.$t('views.fleet.EventCreateDialog.isVirtual'),
        hint: _vm.$t('views.fleet.EventCreateDialog.updateVirtual'),
        readonly: true,
        disabled: true
      }
    }
  }) : _c('custom-field-boolean', {
    attrs: {
      "customField": {
        label: _vm.$t('views.fleet.EventCreateDialog.recurrence'),
        disabled: _vm.isRecurringSwitchDisabled
      }
    },
    model: {
      value: _vm.value.isRecurring,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "isRecurring", $$v);
      },
      expression: "value.isRecurring"
    }
  })], 1)], 1), _vm.value.isRecurring && !_vm.value.isVirtual ? _c('v-row', {
    staticClass: "mb-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "label": _vm.$t('views.fleet.EventCreateDialog.recurring'),
      "items": _vm.value.freqItems,
      "item-text": "text",
      "item-value": "value",
      "outlined": ""
    },
    model: {
      value: _vm.value.ruleOptions.freq,
      callback: function callback($$v) {
        _vm.$set(_vm.value.ruleOptions, "freq", $$v);
      },
      expression: "value.ruleOptions.freq"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "label": _vm.$t('views.fleet.EventCreateDialog.interval'),
      "items": _vm.interval,
      "outlined": ""
    },
    model: {
      value: _vm.value.ruleOptions.interval,
      callback: function callback($$v) {
        _vm.$set(_vm.value.ruleOptions, "interval", $$v);
      },
      expression: "value.ruleOptions.interval"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "label": _vm.$t('views.fleet.EventCreateDialog.recurringEnd'),
      "items": _vm.value.repeatItems,
      "item-text": "text",
      "item-value": "value",
      "outlined": ""
    },
    model: {
      value: _vm.value.limit,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "limit", $$v);
      },
      expression: "value.limit"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.value.limit === 'count' ? _c('v-select', {
    attrs: {
      "label": _vm.$t('views.fleet.EventCreateDialog.recurringRepeats'),
      "items": _vm.interval,
      "item-text": "text",
      "item-value": "value",
      "outlined": ""
    },
    model: {
      value: _vm.value.ruleOptions.count,
      callback: function callback($$v) {
        _vm.$set(_vm.value.ruleOptions, "count", $$v);
      },
      expression: "value.ruleOptions.count"
    }
  }) : _vm._e(), _vm.value.limit === 'until' ? _c('v-text-field', {
    attrs: {
      "type": "date",
      "outlined": ""
    },
    model: {
      value: _vm.value.untilString,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "untilString", $$v);
      },
      expression: "value.untilString"
    }
  }) : _vm._e()], 1), _vm.debug && _vm.value.nextRecurrence ? _c('span', {
    staticClass: "text-caption"
  }, [_vm._v(" " + _vm._s(_vm.value.ruleText) + " starts on " + _vm._s(_vm.value.nextRecurrence) + " ")]) : _vm.debug ? _c('span', {
    staticClass: "text-caption"
  }, [_vm._v(" " + _vm._s(_vm.$t("views.fleet.EventCreateDialog.noRecurring")))]) : _vm._e()], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "label": _vm.$t('views.fleet.EventCreateDialog.description'),
      "outlined": ""
    },
    model: {
      value: _vm.value.description,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "description", $$v);
      },
      expression: "value.description"
    }
  })], 1)], 1), _vm.value.isRecurringRoot ? _c('v-alert', {
    attrs: {
      "type": "warning",
      "outlined": "",
      "prominent": "",
      "text": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("views.fleet.EventCreateDialog.updateAlert")) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }