var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-container', [_c('v-row', [_vm.loading ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-skeleton-loader')], 1) : _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.ANALYTICS, _vm.partnerId) ? _c('v-tab', [_vm._v(" " + _vm._s(_vm.$t("views.portal.PartnerAnalyticsHome.analyticsTabHeader")) + " ")]) : _vm._e(), _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.ANALYTICS, _vm.partnerId) && _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.REPORT_CONTROL) ? _c('v-tab', [_vm._v(" " + _vm._s(_vm.$t("views.portal.PartnerAnalyticsHome.reportControlAnalyticsTabHeader")) + " ")]) : _vm._e(), _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.ANALYTICS) && _vm.isOrganizationAnalyticsEnabled ? _c('v-tab', [_vm._v(" " + _vm._s(_vm.$t("views.portal.PartnerAnalyticsHome.organizationAnalyticsTabHeader")) + " ")]) : _vm._e(), _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.ADMIN) ? _c('v-tab', [_vm._v(" " + _vm._s(_vm.$t("views.portal.PartnerAnalyticsHome.admingCountAnalyticsTabHeader")) + " ")]) : _vm._e()], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.ANALYTICS, _vm.partnerId) ? _c('v-tab-item', [_c('partner-analytics-dashboard')], 1) : _vm._e(), _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.ANALYTICS, _vm.partnerId) && _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.REPORT_CONTROL, _vm.partnerId) ? _c('v-tab-item', [_c('report-control-analytics-dashboard')], 1) : _vm._e(), _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.ANALYTICS) && _vm.isOrganizationAnalyticsEnabled ? _c('v-tab-item', [_c('organization-analytics-dashboard')], 1) : _vm._e(), _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.ADMIN) ? _c('v-tab-item', [_c('admin-count-analytics')], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }