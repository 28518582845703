var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("partnerBanking.partnerBanking")) + " "), _c('v-spacer'), _c('partner-banking-create-dialog', {
    attrs: {
      "hideButton": false,
      "partnerId": _vm.value.partnerId
    },
    on: {
      "created": function created(v) {
        _vm.$toast.success('👍');

        _vm.onCreated(v);
      }
    }
  })], 1), _c('table-wrapper', {
    attrs: {
      "margin": 1,
      "showSelect": false,
      "hideSearch": true,
      "hideTableHeader": true,
      "hideTableFooter": true,
      "allItems": _vm.partnerBankings,
      "headers": _vm.headers,
      "itemKey": "index",
      "dense": false,
      "loading": _vm.isLoadingEntities,
      "isClickableRow": true,
      "controlElements": _vm.controlElements
    },
    on: {
      "click:row": _vm.setSelectedItem
    },
    scopedSlots: _vm._u([{
      key: "item.isSepaDirectDebitMandate",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [value ? _c('v-icon', {
          attrs: {
            "color": "success"
          }
        }, [_vm._v(" mdi-check ")]) : _c('v-icon', {
          attrs: {
            "color": "error"
          }
        }, [_vm._v(" mdi-close ")])];
      }
    }])
  }), _vm.selectedItem ? _c('partner-banking-side-card', {
    attrs: {
      "value": _vm.selectedItem
    },
    on: {
      "close": function close() {
        return _vm.setSelectedItem(null);
      }
    }
  }) : _vm._e(), _vm.isDeleteDialogActive && _vm.value ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "title": _vm.$t('partner.PartnerReportDetailChangeProgress.confirmDeleteTitle'),
      "rightColor": 'error'
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isDeleteDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.onDelete
    }
  }, [_c('refs-partner-banking', {
    attrs: {
      "item": _vm.selectedItemForDeletion
    }
  })], 1) : _vm._e(), _vm.isUpdateDialogActive && _vm.value ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isUpdateDialogActive,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "title": _vm.$t('ActionEnum.update'),
      "loading": _vm.isLoadingPaymentTerm
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isUpdateDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isUpdateDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isUpdateDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isUpdateDialogActive = false;
      },
      "rightClick": _vm.saveEditPaymentTerm
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": 'number',
      "outlined": "",
      "label": _vm.$t('objects.person.billingProfile.paymentTerm')
    },
    model: {
      value: _vm.paymentTermCopy,
      callback: function callback($$v) {
        _vm.paymentTermCopy = $$v;
      },
      expression: "paymentTermCopy"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }