var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('paginated-table', {
    attrs: {
      "store": _vm.localStore,
      "headers": _vm.headers,
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "controlElements": _vm._controlElements,
      "predefinedFilter": _vm.predefinedFilter,
      "showSelect": false
    },
    on: {
      "click:row": _vm.setSelectedItem
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [_c('Can', {
          attrs: {
            "I": _vm.ActionEnum.CREATE,
            "a": _vm.ResourceEnum.CONTRACT,
            "field": _vm.partnerId
          }
        }, [_c('contract-create-dialog', {
          attrs: {
            "hideButton": false,
            "partnerId": _vm.partnerId,
            "refs": _vm.refs,
            "clientIds": _vm.clientIds,
            "providerIds": _vm.providerIds
          },
          on: {
            "created": _vm.setSelectedItem
          }
        })], 1), _vm._t("bodyActions")];
      },
      proxy: true
    }, {
      key: "item.type",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": "info"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("enums.ContractTypeEnum.".concat(item.type))) + " ")])];
      }
    }, {
      key: "item.groupId",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [value ? _c('refs-group', {
          key: 'refs-group' + value,
          attrs: {
            "itemAsync": _vm.getGroup(value)
          }
        }) : _vm._e()];
      }
    }, {
      key: "item.assignees",
      fn: function fn(_ref3) {
        var value = _ref3.value;
        return [value ? _c('assignee-preview', {
          attrs: {
            "value": _vm.toArray(value),
            "amount": 3,
            "partnerId": _vm.partnerId
          }
        }) : _c('div')];
      }
    }, {
      key: "item.title",
      fn: function fn(_ref4) {
        var value = _ref4.value,
            item = _ref4.item;
        return [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.setSelectedItem(item);
            }
          }
        }, [_vm._v(_vm._s(value))])];
      }
    }, {
      key: "item.startDate",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [value ? _c('v-chip', {
          attrs: {
            "outlined": "",
            "x-small": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "x-small": "",
            "left": ""
          }
        }, [_vm._v("mdi-calendar-start")]), _vm._v(" " + _vm._s(_vm.simpleDoubleDigitDate(value)) + " ")], 1) : _c('div')];
      }
    }, {
      key: "item.endDate",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.endDate ? _c('due-date-chip', {
          attrs: {
            "ticket": {
              due: item.endDate
            }
          }
        }) : _c('div')];
      }
    }], null, true)
  }), _vm.selectedItem ? _c('contract-side-card', {
    attrs: {
      "value": _vm.selectedItem
    },
    on: {
      "close": function close() {
        return _vm.setSelectedItem(null);
      }
    }
  }) : _vm._e(), _vm.isDeleteDialogActive && _vm.selectedItemForDelete ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "title": _vm.$t('partner.PartnerReportDetailChangeProgress.confirmDeleteTitle'),
      "rightColor": 'error',
      "rightLoading": _vm.isDeleteLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isDeleteDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.onDelete
    }
  }, [_c('refs-contract', {
    attrs: {
      "item": _vm.selectedItemForDelete
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }