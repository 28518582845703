var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.component, {
    ref: _vm.component,
    tag: "component",
    attrs: {
      "value": _vm.value
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }