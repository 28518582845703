var render = function () {
  var _ref, _vm$search;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', {
    attrs: {
      "isFillHeight": true
    }
  }, [_c('company-map', {
    staticClass: "fill-height flex-grow-1",
    attrs: {
      "coordinates": _vm.coordinates,
      "colour": _vm.color,
      "search": (_ref = (_vm$search = _vm.search) !== null && _vm$search !== void 0 ? _vm$search : _vm.query) !== null && _ref !== void 0 ? _ref : _vm.parsed,
      "filter": _vm.filter
    },
    on: {
      "click": _vm.onClick
    }
  }), _vm.selectedCompany ? _c('company-side-card', {
    attrs: {
      "value": _vm.selectedCompany
    },
    on: {
      "close": function close($event) {
        _vm.selectedCompany = null;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }