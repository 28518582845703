var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Can', {
    attrs: {
      "I": _vm.ActionEnum.CREATE,
      "a": _vm.ResourceEnum.TICKET
    }
  }, [_vm._t("activator", function () {
    return [_c('v-btn', {
      attrs: {
        "text": "",
        "elevation": 0
      },
      on: {
        "click": _vm.open
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("completed")) + " ")])];
  }, {
    "slotScope": {
      open: _vm.open
    }
  })], 2), _vm.isDialogActive ? _c('confirm-action-dialog', {
    key: 'confirm-action-dialog' + _vm.isDialogActive,
    attrs: {
      "isDialogActive": _vm.isDialogActive,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "loading": _vm.isLoading,
      "title": _vm.$t('project.ticket.markCompleted'),
      "supressKeyboardActions": true,
      "rightLoading": _vm.isLoading,
      "rightDisabled": !_vm.value.length,
      "leftText": _vm.$t('abort'),
      "rightText": _vm.$t('completed')
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "leftClick": _vm.close,
      "rightClick": _vm.completeTickets,
      "close": _vm.close
    }
  }, _vm._l(_vm.value, function (ticket) {
    return _c('refs-ticket', {
      key: 'ref-ticket-ack-dialog' + ticket.id,
      attrs: {
        "item": ticket
      },
      scopedSlots: _vm._u([{
        key: "icon",
        fn: function fn() {
          return [_c('div', {
            staticClass: "mr-2"
          }, [ticket.loading ? _c('v-progress-circular', {
            attrs: {
              "indeterminate": "",
              "color": "primary",
              "size": "24"
            }
          }) : ticket.state === _vm.TicketStatusEnum.CLOSED ? _c('v-icon', [_vm._v(" mdi-check ")]) : _vm._e()], 1)];
        },
        proxy: true
      }], null, true)
    });
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }