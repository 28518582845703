var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_vm.partner ? _c('v-container', [_c('m-header', {
    attrs: {
      "breadCrumbs": _vm.breadCrumbList,
      "title": _vm.$t("group.".concat(_vm.type, ".table")),
      "chips": _vm.chips,
      "actions": _vm.actions
    }
  }), _c('group-type-table', {
    ref: "table",
    attrs: {
      "partnerId": _vm.partnerId,
      "type": _vm.type
    },
    on: {
      "openSideCard": _vm.goToCustomView
    }
  })], 1) : _c('v-container', [_c('v-skeleton-loader')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }