var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', {
    staticClass: "ma-4",
    attrs: {
      "flat": ""
    }
  }, [_vm.goBack ? _c('v-card-text', [_c('a', {
    on: {
      "click": _vm.goBackTo
    }
  }, [_c('v-icon', [_vm._v(" mdi-menu-left ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")])], 1)]) : _vm._e(), _vm.title ? _c('v-card-title', [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm._l(_vm.importConfig, function (config, idx) {
    return _c('div', {
      key: idx
    }, [_vm.hasPermissionOnItemInCategory(config) ? _c('span', [_c('v-subheader', [_vm._v(_vm._s(config.category))]), _c('v-list', _vm._l(config.items, function (item, idx) {
      return _c('span', {
        key: idx
      }, [item.hasPermission ? _c('v-list-item', [_c('v-list-item-avatar', [item.icon ? _c('v-icon', [_vm._v(" " + _vm._s(item.icon) + " ")]) : _c('v-icon', [_vm._v(" mdi-database-import-outline ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.title))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(item.description))])], 1), _c('v-list-item-action', {
        staticStyle: {
          "display": "grid",
          "grid-template-columns": "repeat(2, auto)"
        }
      }, [item.template ? _c('v-btn', {
        staticClass: "mr-2",
        attrs: {
          "small": "",
          "outlined": "",
          "color": "info"
        },
        on: {
          "click": item.template
        }
      }, [_vm._v(" Template ")]) : _vm._e(), _vm._t("default", null, null, item)], 2)], 1) : _vm._e()], 1);
    }), 0)], 1) : _vm._e()]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }