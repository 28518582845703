var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.icon && !_vm.hideButton ? _c('v-btn', {
    attrs: {
      "small": "",
      "elevation": "0",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("views.fleet.EventCreateDialog.btn")) + " ")]) : !_vm.hideButton ? _c('v-btn', {
    attrs: {
      "text": "",
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = true;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1) : _vm._e(), _vm.dialog ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.dialog,
      "supressKeyboardActions": true,
      "title": _vm.$t('views.fleet.EventCreateDialog.title'),
      "leftText": _vm.$t('views.fleet.EventCreateDialog.close'),
      "rightText": _vm.$t('views.fleet.EventCreateDialog.save'),
      "rightDisabled": !_vm.isValid,
      "rightLoading": _vm.loading || _vm.isLoadingEvent
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.dialog = false;
      },
      "rightClick": _vm.onCreateEvent
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-menu', {
          attrs: {
            "left": "",
            "max-width": "400"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "text": "",
                  "color": "info"
                }
              }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm.$t("views.fleet.EventCreateDialog.template")) + " ")])];
            }
          }], null, false, 2750265066)
        }, [_c('v-list', _vm._l(_vm.templates, function (item, idx) {
          return _c('v-list-item', {
            key: idx,
            attrs: {
              "two-line": "",
              "value": idx
            },
            on: {
              "click": function click($event) {
                return _vm.updateEvent(item);
              }
            }
          }, [_c('v-list-item-content', [_c('v-list-item-title', {
            staticClass: "text-wrap"
          }, [_vm._v(_vm._s(_vm.$t(item.title)))]), _c('v-list-item-subtitle', {
            staticClass: "text-wrap"
          }, [_vm._v(_vm._s(_vm.$t(item.description)))])], 1)], 1);
        }), 1)], 1)];
      },
      proxy: true
    }], null, false, 2099624962)
  }, [_c('event-create-form', {
    ref: "eventForm",
    attrs: {
      "partnerId": _vm.partner.id,
      "showVehicleSelection": _vm.showVehicleSelection,
      "vehicleId": _vm.selectedVehicle
    },
    on: {
      "isValid": function isValid($event) {
        _vm.isValid = $event;
      },
      "update:vehicleId": function updateVehicleId($event) {
        _vm.selectedVehicle = $event;
      },
      "update:vehicle-id": function updateVehicleId($event) {
        _vm.selectedVehicle = $event;
      }
    },
    model: {
      value: _vm.event,
      callback: function callback($$v) {
        _vm.event = $$v;
      },
      expression: "event"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }