var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.hideButton ? _c('v-btn', {
    attrs: {
      "elevation": 0
    },
    on: {
      "click": _vm.open
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")]) : _vm._e(), _vm.isDialogActive ? _c('confirm-action-dialog', {
    attrs: {
      "width": "1050",
      "persistent": true,
      "supressKeyboardActions": true,
      "title": _vm.$t('person.createPerson'),
      "isDialogActive": _vm.isDialogActive,
      "rightText": _vm.$t('create'),
      "loading": _vm.isLoading,
      "fullscreen": _vm.fullscreen,
      "rightLoading": _vm.isLoading,
      "rightDisabled": !_vm.isFormValid
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "leftClick": _vm.close,
      "close": _vm.close,
      "rightClick": _vm.onRightClick
    }
  }, [_c('v-form', {
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('v-row', {
    staticClass: "pt-3"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-text-field', {
    ref: "personNameField",
    attrs: {
      "label": _vm.$t('objects.person.firstName'),
      "outlined": ""
    },
    model: {
      value: _vm.person.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.person, "firstName", $$v);
      },
      expression: "person.firstName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.person.lastName'),
      "outlined": ""
    },
    model: {
      value: _vm.person.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.person, "lastName", $$v);
      },
      expression: "person.lastName"
    }
  }), _c('template-editor', {
    staticClass: "mb-3",
    staticStyle: {
      "height": "200px"
    },
    attrs: {
      "outlined": true,
      "label": _vm.$t('objects.person.description')
    },
    model: {
      value: _vm.person.description,
      callback: function callback($$v) {
        _vm.$set(_vm.person, "description", $$v);
      },
      expression: "person.description"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_vm._l(_vm.person.emails, function (email, index) {
    return _c('div', {
      key: index
    }, [_c('v-subheader', [_vm._v(" " + _vm._s(_vm.$t("common.nouns.email")) + " #" + _vm._s(index + 1) + " "), _c('v-spacer'), _c('v-btn', {
      attrs: {
        "text": "",
        "x-small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.person.emails.splice(index, 1);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("person.removeEmail")) + " ")])], 1), _c('v-text-field', {
      attrs: {
        "label": _vm.$t('objects.person.emails.email') + ' *',
        "rules": _vm.emailRule,
        "outlined": ""
      },
      model: {
        value: email.email,
        callback: function callback($$v) {
          _vm.$set(email, "email", $$v);
        },
        expression: "email.email"
      }
    }), _c('v-autocomplete', {
      attrs: {
        "items": Object.values(_vm.PersonLabelEnum),
        "label": _vm.$t('objects.person.emails.label'),
        "auto-select-first": "",
        "outlined": ""
      },
      scopedSlots: _vm._u([{
        key: "item",
        fn: function fn(_ref) {
          var item = _ref.item;
          return [_vm._v(" " + _vm._s(_vm.$t("enums.PersonLabelEnum.".concat(item))) + " ")];
        }
      }, {
        key: "selection",
        fn: function fn(_ref2) {
          var item = _ref2.item;
          return [_vm._v(" " + _vm._s(_vm.$t("enums.PersonLabelEnum.".concat(item))) + " ")];
        }
      }], null, true),
      model: {
        value: email.label,
        callback: function callback($$v) {
          _vm.$set(email, "label", $$v);
        },
        expression: "email.label"
      }
    }), _c('v-textarea', {
      attrs: {
        "label": _vm.$t('objects.person.emails.notes'),
        "auto-select-first": "",
        "outlined": "",
        "multiple": "",
        "clearable": ""
      },
      model: {
        value: email.notes,
        callback: function callback($$v) {
          _vm.$set(email, "notes", $$v);
        },
        expression: "email.notes"
      }
    })], 1);
  }), _c('v-card', {
    staticClass: "d-flex mb-8",
    attrs: {
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.person.emails.push(new _vm.PersonEmail());
      }
    }
  }, [_c('v-list-item', {
    staticStyle: {
      "height": "56px"
    }
  }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("person.addEmail")) + " ")])], 1)], 1), _vm._l(_vm.person.phones, function (phone, index) {
    return _c('div', {
      key: index
    }, [_c('v-subheader', [_vm._v(" " + _vm._s(_vm.$t("common.nouns.phone")) + " #" + _vm._s(index + 1) + " "), _c('v-spacer'), _c('v-btn', {
      attrs: {
        "text": "",
        "x-small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.person.phones.splice(index, 1);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("person.removePhone")) + " ")])], 1), _c('v-text-field', {
      attrs: {
        "label": _vm.$t('objects.person.phones.phone') + ' *',
        "rules": _vm.phoneRule,
        "outlined": ""
      },
      model: {
        value: phone.phone,
        callback: function callback($$v) {
          _vm.$set(phone, "phone", $$v);
        },
        expression: "phone.phone"
      }
    }), _c('v-autocomplete', {
      attrs: {
        "items": Object.values(_vm.PersonLabelEnum),
        "label": _vm.$t('objects.person.phones.label'),
        "auto-select-first": "",
        "outlined": ""
      },
      scopedSlots: _vm._u([{
        key: "item",
        fn: function fn(_ref3) {
          var item = _ref3.item;
          return [_vm._v(" " + _vm._s(_vm.$t("enums.PersonLabelEnum.".concat(item))) + " ")];
        }
      }, {
        key: "selection",
        fn: function fn(_ref4) {
          var item = _ref4.item;
          return [_vm._v(" " + _vm._s(_vm.$t("enums.PersonLabelEnum.".concat(item))) + " ")];
        }
      }], null, true),
      model: {
        value: phone.label,
        callback: function callback($$v) {
          _vm.$set(phone, "label", $$v);
        },
        expression: "phone.label"
      }
    }, [_vm._v("s ")]), _c('v-textarea', {
      attrs: {
        "label": _vm.$t('objects.person.phones.notes'),
        "auto-select-first": "",
        "outlined": "",
        "multiple": "",
        "clearable": ""
      },
      model: {
        value: phone.notes,
        callback: function callback($$v) {
          _vm.$set(phone, "notes", $$v);
        },
        expression: "phone.notes"
      }
    })], 1);
  }), _c('v-card', {
    staticClass: "d-flex mb-8",
    attrs: {
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.person.phones.push(new _vm.PersonPhone());
      }
    }
  }, [_c('v-list-item', {
    staticStyle: {
      "height": "56px"
    }
  }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("person.addPhone")) + " ")])], 1)], 1), _vm._l(_vm.person.addresses, function (address, index) {
    return _c('div', {
      key: index
    }, [_c('v-subheader', [_vm._v(" " + _vm._s(_vm.$t("objects.address.address")) + " #" + _vm._s(index + 1) + " "), _c('v-spacer'), _c('v-btn', {
      attrs: {
        "text": "",
        "x-small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.person.addresses.splice(index, 1);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("person.removeAddress")) + " ")])], 1), _c('v-text-field', {
      attrs: {
        "label": _vm.$t('objects.address.street'),
        "outlined": ""
      },
      model: {
        value: address.street,
        callback: function callback($$v) {
          _vm.$set(address, "street", $$v);
        },
        expression: "address.street"
      }
    }), _c('v-text-field', {
      attrs: {
        "label": _vm.$t('objects.address.zip'),
        "outlined": ""
      },
      model: {
        value: address.zip,
        callback: function callback($$v) {
          _vm.$set(address, "zip", $$v);
        },
        expression: "address.zip"
      }
    }), _c('v-text-field', {
      attrs: {
        "label": _vm.$t('objects.address.city'),
        "outlined": ""
      },
      model: {
        value: address.city,
        callback: function callback($$v) {
          _vm.$set(address, "city", $$v);
        },
        expression: "address.city"
      }
    }), _c('v-text-field', {
      attrs: {
        "label": _vm.$t('objects.address.state'),
        "outlined": ""
      },
      model: {
        value: address.state,
        callback: function callback($$v) {
          _vm.$set(address, "state", $$v);
        },
        expression: "address.state"
      }
    }), _c('v-autocomplete', {
      attrs: {
        "items": Object.values(_vm.CountryCodeEnum),
        "label": _vm.$t('objects.address.countryCode'),
        "auto-select-first": "",
        "outlined": ""
      },
      scopedSlots: _vm._u([{
        key: "item",
        fn: function fn(_ref5) {
          var item = _ref5.item;
          return [_vm._v(" " + _vm._s(_vm.$t("CountryCodeEnum.".concat(item))) + " ")];
        }
      }, {
        key: "selection",
        fn: function fn(_ref6) {
          var item = _ref6.item;
          return [_vm._v(" " + _vm._s(_vm.$t("CountryCodeEnum.".concat(item))) + " ")];
        }
      }], null, true),
      model: {
        value: address.countryCode,
        callback: function callback($$v) {
          _vm.$set(address, "countryCode", $$v);
        },
        expression: "address.countryCode"
      }
    }), _c('custom-field-boolean', {
      attrs: {
        "customField": {
          label: _vm.$t('objects.person.addresses.isBilling')
        }
      },
      model: {
        value: address.isBilling,
        callback: function callback($$v) {
          _vm.$set(address, "isBilling", $$v);
        },
        expression: "address.isBilling"
      }
    })], 1);
  }), _c('v-card', {
    staticClass: "d-flex mb-8",
    attrs: {
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.person.addresses.push(new _vm.CompanyAddress());
      }
    }
  }, [_c('v-list-item', {
    staticStyle: {
      "height": "56px"
    }
  }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("person.addAddress")) + " ")])], 1)], 1), _c('v-autocomplete', {
    attrs: {
      "item-value": "id",
      "items": _vm.groups,
      "label": _vm.$t('objects.person.groupId'),
      "auto-select-first": "",
      "outlined": "",
      "clearable": "",
      "loading": _vm.isLoadingPersonGroups
    },
    on: {
      "click": _vm.refreshPersonGroups
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.title) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.title) + " ")];
      }
    }], null, false, 2714690748),
    model: {
      value: _vm.person.groupId,
      callback: function callback($$v) {
        _vm.$set(_vm.person, "groupId", $$v);
      },
      expression: "person.groupId"
    }
  }), _c('div', {
    staticClass: "mt-n7",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "x-small": "",
      "text": "",
      "elevation": 0,
      "loading": _vm.isLoadingPersonGroups
    },
    on: {
      "click": _vm.refreshPersonGroups
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.verbs.refresh")) + " ")]), _c('v-btn', {
    attrs: {
      "x-small": "",
      "text": "",
      "elevation": 0
    },
    on: {
      "click": _vm.goToPersonGroupCustomView
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.verbs.view")) + " ")])], 1), _vm.personGroup && _vm.personGroup.configuration && _vm.personGroup.configuration.customFieldConfig && _vm.personGroup.configuration.customFieldConfig.length ? _c('div', [_vm.isLoadingCustomFieldValues ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  }) : _vm._e(), _c('custom-field-list-form', {
    staticClass: "mt-6",
    attrs: {
      "customFieldConfig": _vm.personGroup.configuration.customFieldConfig
    },
    model: {
      value: _vm.person.values,
      callback: function callback($$v) {
        _vm.$set(_vm.person, "values", $$v);
      },
      expression: "person.values"
    }
  }), _c('debug', [_vm._v(" person.values: " + _vm._s(_vm.person.values)), _c('br'), _vm._v(" person.groupId: " + _vm._s(_vm.person.groupId)), _c('br'), _vm._v(" personGroup: " + _vm._s(_vm.personGroup)), _c('br')])], 1) : _vm._e()], 2)], 1), _c('debug', [_vm._v(" " + _vm._s(_vm.person) + " ")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }