var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_vm.isLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  }) : _vm.costGroup ? _c('div', [_c('m-header', {
    attrs: {
      "breadCrumbs": _vm.breadCrumbList,
      "title": _vm.costGroup.title,
      "actions": _vm.actions,
      "viewId": _vm.viewIdLocal,
      "chips": _vm.chips
    },
    on: {
      "update:viewId": function updateViewId($event) {
        _vm.viewIdLocal = $event;
      },
      "update:view-id": function updateViewId($event) {
        _vm.viewIdLocal = $event;
      },
      "actionClicked": function actionClicked(action) {
        return action.exec();
      }
    }
  }), _c('custom-views', {
    ref: "customViews",
    attrs: {
      "store": _vm.store,
      "entity": _vm.costGroup,
      "viewId": _vm.viewIdLocal,
      "refType": _vm.BackendResourceEnum.COST,
      "boardBaseConfig": _vm.boardBaseConfig,
      "calendarBaseConfig": _vm.calendarBaseConfig,
      "tableBaseConfig": _vm.tableBaseConfig
    },
    on: {
      "update:viewId": function updateViewId($event) {
        _vm.viewIdLocal = $event;
      },
      "update:view-id": function updateViewId($event) {
        _vm.viewIdLocal = $event;
      },
      "openSideCard": _vm.openSideCard,
      "goToSettings": _vm.goToSettings
    },
    scopedSlots: _vm._u([{
      key: "group.header",
      fn: function fn(_ref) {
        var groupBy = _ref.groupBy,
            group = _ref.group,
            toggle = _ref.toggle,
            isOpen = _ref.isOpen,
            items = _ref.items;
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "align-items": "center"
          },
          on: {
            "click": toggle
          }
        }, [groupBy[0].includes('refs') && groupBy[0].includes(_vm.BackendResourceEnum.VEHICLE) ? _c('refs-vehicle', {
          attrs: {
            "item": _vm.getVehicleById(group)
          },
          on: {
            "openDetail": _vm.goToVehicleDetail
          }
        }) : _vm.isDayTimeString(group) ? _c('span', [_vm._v(_vm._s(_vm.getDateTime(group)))]) : group ? _c('span', [_vm._v(_vm._s(group))]) : _vm._e(), _c('v-spacer'), groupBy[0].includes('refs') && groupBy[0].includes(_vm.BackendResourceEnum.VEHICLE) ? _c('Can', {
          attrs: {
            "I": _vm.ActionEnum.CREATE,
            "a": _vm.ResourceEnum.COST
          }
        }, [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.createCostFromVehicle(group);
            }
          }
        }, [_c('v-icon', [_vm._v(" mdi-plus ")])], 1)], 1) : _vm._e(), isOpen ? _c('v-icon', [_vm._v("mdi-chevron-down")]) : _c('v-icon', [_vm._v("mdi-chevron-right")]), _c('cost-chip', {
          attrs: {
            "value": {
              type: _vm.getTotalForItems(items) < 0 ? _vm.CostTypeEnum.EXPENSE : _vm.CostTypeEnum.INCOME,
              total: _vm.getTotalForItems(items) || 0,
              totalReadable: (_vm.getTotalForItems(items) || 0).toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR'
              })
            }
          }
        })], 1)];
      }
    }, {
      key: "item.total",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('cost-chip', {
          attrs: {
            "value": item,
            "small": true
          }
        })];
      }
    }, {
      key: "chip",
      fn: function fn(_ref3) {
        var items = _ref3.items;
        return [_c('cost-chip', {
          attrs: {
            "value": {
              type: _vm.getTotalForItems(items) < 0 ? _vm.CostTypeEnum.EXPENSE : _vm.CostTypeEnum.INCOME,
              total: _vm.getTotalForItems(items) || 0,
              totalReadable: (_vm.getTotalForItems(items) || 0).toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR'
              })
            }
          }
        })];
      }
    }, {
      key: "item.title",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.openSideCard(item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.title) + " ")])];
      }
    }, {
      key: "item.date",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(item.date)) + " ")];
      }
    }, {
      key: "item.tags",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return _vm._l(item.tags, function (tag) {
          return _c('v-chip', {
            key: 'tag' + item.id + tag,
            staticClass: "mr-1",
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(tag) + " ")]);
        });
      }
    }])
  })], 1) : _c('latest-entries-card-empty'), _vm.selectedItem ? _c('cost-side-card', {
    attrs: {
      "value": _vm.selectedItem
    },
    on: {
      "close": _vm.closeSideCard
    }
  }) : _vm._e(), _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.CREATE,
      "a": _vm.ResourceEnum.COST,
      "field": _vm.partnerId
    }
  }, [_c('cost-create-dialog', {
    ref: "costDialog",
    attrs: {
      "hideButton": true,
      "partnerId": _vm.partnerId,
      "refs": _vm.createCostRefs,
      "group": _vm.costGroupId,
      "isDialogActive": _vm.isCostCreateDialogActive
    },
    on: {
      "update:isDialogActive": _vm.closeDialog
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }