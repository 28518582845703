var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-container', [_c('m-header', {
    attrs: {
      "title": _vm.$t('common.nouns.appointments')
    }
  }), _c('div', {
    staticClass: "mx-4"
  }, [_c('v-tabs', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tabs-slider', {
    attrs: {
      "color": "yellow"
    }
  }), _vm._l(_vm.tabs, function (item) {
    return _c('v-tab', {
      key: item.key,
      staticClass: "v-tabs__container"
    }, [item.icon ? _c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v(_vm._s(item.icon))]) : _vm._e(), _vm._v(" " + _vm._s(item.text) + " ")], 1);
  })], 2), _c('v-divider')], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "data-test-type": "overdue"
    }
  }, [_c('table-wrapper', {
    attrs: {
      "showSelect": true,
      "selectedItems": _vm.selectedOverdueLocal,
      "allItems": _vm.overdueEvents,
      "headers": _vm.headersOverdue,
      "sortBy": "event.start",
      "sortDesc": false,
      "itemKey": "index",
      "hideSearch": true,
      "loading": _vm.loading
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selectedOverdueLocal = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selectedOverdueLocal = $event;
      },
      "click:row": function clickRow($event) {
        return _vm.onRowClick($event.event);
      }
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [_vm.selectedOverdueLocal.length ? _c('Can', {
          attrs: {
            "I": _vm.ActionEnum.UPDATE,
            "a": _vm.ResourceEnum.EVENT,
            "field": _vm.partnerId
          }
        }, [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('span', {
          staticClass: "caption"
        }, [_vm._v(_vm._s(_vm.selectedOverdueLocal.length) + " " + _vm._s(_vm.$t("selected")))]), _c('v-icon', {
          staticClass: "mx-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-arrow-right")]), _c('event-acknowledge-dialog', {
          attrs: {
            "value": _vm.selectedOverdueLocal.map(function (e) {
              return e.event;
            })
          },
          on: {
            "acknowledged": _vm.onAcknowledged
          }
        })], 1)]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "search",
      fn: function fn() {
        return [_c('filter-card-pagination', {
          staticClass: "ma-n4",
          attrs: {
            "filterList": _vm.overdueEventsFilterList,
            "filter": _vm.overdueEventsFilters,
            "search": _vm.overdueEventsSearch,
            "hideDivider": true,
            "predefinedFilter": _vm.predefinedFilter
          },
          on: {
            "update:filter": function updateFilter($event) {
              _vm.overdueEventsFilters = $event;
            },
            "update:search": function updateSearch($event) {
              _vm.overdueEventsSearch = $event;
            },
            "confirmFilter": _vm.onConfirmOverdueEventsFilter
          }
        })];
      },
      proxy: true
    }, {
      key: "item.event.isVirtual",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.event.recurringEventId ? _c('v-icon', [_vm._v("mdi-autorenew")]) : _c('v-icon')];
      }
    }, {
      key: "item.event.isDeleted",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.event.isDeleted ? _c('v-icon', [_vm._v("mdi-tash-can")]) : _c('v-icon')];
      }
    }, {
      key: "item.event.userId",
      fn: function fn(_ref3) {
        var _vm$getUser, _vm$getUser2;

        var item = _ref3.item;
        return [_vm._v(" " + _vm._s((_vm$getUser = _vm.getUser(item.event.userId)) === null || _vm$getUser === void 0 ? void 0 : _vm$getUser.firstName) + " " + _vm._s((_vm$getUser2 = _vm.getUser(item.event.userId)) === null || _vm$getUser2 === void 0 ? void 0 : _vm$getUser2.lastName) + " ")];
      }
    }, {
      key: "item.event.vehicleId",
      fn: function fn(_ref4) {
        var _vm$getVehicle$displa, _vm$getVehicle;

        var item = _ref4.item;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'FleetVehicleDetail',
              params: {
                partnerId: item.event.partnerId,
                vehicleId: item.event.vehicleId
              }
            }
          }
        }, [_vm._v(" " + _vm._s((_vm$getVehicle$displa = (_vm$getVehicle = _vm.getVehicle(item.event.vehicleId)) === null || _vm$getVehicle === void 0 ? void 0 : _vm$getVehicle.displayName) !== null && _vm$getVehicle$displa !== void 0 ? _vm$getVehicle$displa : item.event.vehicleId) + " ")])];
      }
    }])
  }), _c('debug', {
    attrs: {
      "debug": _vm.overdueEvents
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "data-test-type": "list"
    }
  }, [_c('table-wrapper', {
    attrs: {
      "loading": _vm.loading,
      "allItems": _vm.listEvents,
      "headers": _vm.headersList,
      "sortBy": "event.start",
      "sortDesc": false,
      "itemKey": "index",
      "hideSearch": true
    },
    on: {
      "click:row": function clickRow($event) {
        return _vm.onRowClick($event.event);
      }
    },
    scopedSlots: _vm._u([{
      key: "search",
      fn: function fn() {
        return [_c('filter-card-pagination', {
          staticClass: "ma-n4",
          attrs: {
            "filterList": _vm.allEventsFilterList,
            "filter": _vm.allEventsFilters,
            "search": _vm.allEventsSearch,
            "hideDivider": true,
            "predefinedFilter": _vm.predefinedFilter
          },
          on: {
            "update:filter": function updateFilter($event) {
              _vm.allEventsFilters = $event;
            },
            "update:search": function updateSearch($event) {
              _vm.allEventsSearch = $event;
            },
            "confirmFilter": _vm.onConfirmAllEventsFilter
          }
        })];
      },
      proxy: true
    }, {
      key: "item.event.summary",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          style: _vm.isDue(item) ? 'color: red' : ''
        }, [_vm._v(" " + _vm._s(item.event.summary) + " ")])];
      }
    }, {
      key: "item.event.isVirtual",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.event.recurringEventId ? _c('v-icon', [_vm._v("mdi-autorenew")]) : _c('v-icon')];
      }
    }, {
      key: "item.event.userId",
      fn: function fn(_ref7) {
        var _vm$getUser3, _vm$getUser4;

        var item = _ref7.item;
        return [_vm._v(" " + _vm._s((_vm$getUser3 = _vm.getUser(item.event.userId)) === null || _vm$getUser3 === void 0 ? void 0 : _vm$getUser3.firstName) + " " + _vm._s((_vm$getUser4 = _vm.getUser(item.event.userId)) === null || _vm$getUser4 === void 0 ? void 0 : _vm$getUser4.lastName) + " ")];
      }
    }, {
      key: "item.event.vehicleId",
      fn: function fn(_ref8) {
        var _vm$getVehicle$displa2, _vm$getVehicle2;

        var item = _ref8.item;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'FleetVehicleDetail',
              params: {
                partnerId: item.event.partnerId,
                vehicleId: item.event.vehicleId
              }
            }
          }
        }, [_vm._v(" " + _vm._s((_vm$getVehicle$displa2 = (_vm$getVehicle2 = _vm.getVehicle(item.event.vehicleId)) === null || _vm$getVehicle2 === void 0 ? void 0 : _vm$getVehicle2.displayName) !== null && _vm$getVehicle$displa2 !== void 0 ? _vm$getVehicle$displa2 : item.event.vehicleId) + " ")])];
      }
    }])
  }), _c('debug', {
    attrs: {
      "debug": _vm.listEvents
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "data-test-type": "recurring"
    }
  }, [_c('paginated-table', {
    attrs: {
      "loading": _vm.loading,
      "getModule": _vm.getModule,
      "headers": _vm.headersRecurring,
      "predefinedFilter": _vm.predefinedFilter,
      "sortBy": "timestamp.created"
    },
    on: {
      "refresh": _vm.load,
      "click:row": _vm.onRowClick
    },
    scopedSlots: _vm._u([{
      key: "item.isDeleted",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [item.isDeleted ? _c('v-icon', [_vm._v("mdi-calendar-remove")]) : _c('v-icon')];
      }
    }, {
      key: "item.userId",
      fn: function fn(_ref10) {
        var _vm$getUser5, _vm$getUser6;

        var item = _ref10.item;
        return [_vm._v(" " + _vm._s((_vm$getUser5 = _vm.getUser(item.userId)) === null || _vm$getUser5 === void 0 ? void 0 : _vm$getUser5.firstName) + " " + _vm._s((_vm$getUser6 = _vm.getUser(item.userId)) === null || _vm$getUser6 === void 0 ? void 0 : _vm$getUser6.lastName) + " ")];
      }
    }, {
      key: "item.rrule",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(_vm._f("rruleToText")(item.rrule)) + " ")];
      }
    }, {
      key: "item.vehicleId",
      fn: function fn(_ref12) {
        var _vm$getVehicle$displa3, _vm$getVehicle3;

        var item = _ref12.item;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'FleetVehicleDetail',
              params: {
                partnerId: item.partnerId,
                vehicleId: item.vehicleId
              }
            }
          }
        }, [_vm._v(" " + _vm._s((_vm$getVehicle$displa3 = (_vm$getVehicle3 = _vm.getVehicle(item.vehicleId)) === null || _vm$getVehicle3 === void 0 ? void 0 : _vm$getVehicle3.displayName) !== null && _vm$getVehicle$displa3 !== void 0 ? _vm$getVehicle$displa3 : item.vehicleId) + " ")])];
      }
    }])
  }), _c('debug', {
    attrs: {
      "debug": _vm.recurringEvents
    }
  })], 1)], 1)], 1), _vm.selectedEvent ? _c('event-side-card', {
    attrs: {
      "value": _vm.selectedEvent
    },
    on: {
      "close": function close($event) {
        _vm.selectedEvent = null;
      },
      "input": _vm.onUpdateEvent,
      "delete": _vm.onDeleteEvent,
      "goToDetail": _vm.goToDetail,
      "goToEdit": _vm.goToEdit
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }