var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_vm.partner ? _c('v-container', [_c('m-header', {
    attrs: {
      "breadCrumbs": _vm.breadCrumbList,
      "title": _vm.$t('partnerBanking.partnerBankingTable'),
      "chips": _vm.chips
    }
  }), _c('partner-banking-table', {
    ref: "partnerBankingTable",
    attrs: {
      "partnerId": _vm.partnerId,
      "selectedItems": _vm.selectedItems
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      },
      "openSideCard": _vm.openSideCard
    }
  })], 1) : _c('v-container', [_c('v-skeleton-loader')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }