var render = function () {
  var _vm$vehicle, _vm$vehicle2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_vm.loading ? _c('v-container', [_c('v-skeleton-loader', {
    attrs: {
      "type": "text, card-heading, button, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1) : _vm.vehicle ? _c('v-container', [_vm.loadingUpdate ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  }) : _vm._e(), _c('m-header', {
    key: 'm-header-vehicle' + _vm.updateKey,
    attrs: {
      "title": "".concat(_vm.$t('components.fleet.contract.detailsFor'), " ").concat(((_vm$vehicle = _vm.vehicle) === null || _vm$vehicle === void 0 ? void 0 : _vm$vehicle.displayName) || ((_vm$vehicle2 = _vm.vehicle) === null || _vm$vehicle2 === void 0 ? void 0 : _vm$vehicle2.numberplate)),
      "breadCrumbs": _vm.breadCrumbs,
      "actions": _vm.actions
    },
    on: {
      "actionClicked": _vm.processAction
    }
  }), _c('m-detail-view-grid', {
    attrs: {
      "bottomSheet": false
    }
  }, [_c('update-form', {
    attrs: {
      "formable": _vm.formable,
      "customConfigKey": 'groupId',
      "containButtons": false,
      "partnerId": _vm.partnerId
    },
    on: {
      "input": _vm.onInput
    },
    model: {
      value: _vm.vehicle,
      callback: function callback($$v) {
        _vm.vehicle = $$v;
      },
      expression: "vehicle"
    }
  })], 1)], 1) : _vm._e(), _vm.isDeleteDialogActive ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "title": _vm.$t('delete')
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isDeleteDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.confirmDeletion
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }