var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_vm.isLoading ? _c('v-container', [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, actions, heading, table-row, list-item-two-line@4"
    }
  })], 1) : _vm.handover ? _c('v-container', {
    directives: [{
      name: "resize",
      rawName: "v-resize",
      value: _vm.measureWidth,
      expression: "measureWidth"
    }],
    ref: "container"
  }, [_c('m-header', {
    attrs: {
      "title": _vm.title,
      "breadCrumbs": _vm.breadCrumbs,
      "actions": _vm.actions
    },
    on: {
      "actionClicked": function actionClicked($event) {
        return $event.exec();
      }
    }
  }), _c('table-wrapper', {
    attrs: {
      "allItems": _vm.agreements,
      "headers": _vm.headers,
      "controlElements": _vm.controlElements,
      "sortBy": "index",
      "sortDesc": true
    },
    scopedSlots: _vm._u([{
      key: "item.index",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-list-item', {
          style: "width: ".concat(_vm.containerWidth - (_vm.$vuetify.breakpoint.smAndDown ? 60 : 200), "px"),
          attrs: {
            "id": _vm.getIndex(item.index)
          }
        }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_c('p', {
          staticStyle: {
            "white-space": "pre-line"
          }
        }, [_vm._v(" " + _vm._s(item.agreement.description) + " ")])]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(item.agreement.timestamp.created)) + " ")])], 1)], 1)];
      }
    }])
  })], 1) : _c('latest-entries-card-empty'), _c('create-agreement-dialog', {
    ref: "createAgreementDialog",
    attrs: {
      "value": _vm.handover
    }
  }), _c('confirm-action-dialog', {
    attrs: {
      "loading": _vm.isUpdateLoading,
      "isDialogActive": _vm.isEditDialogActive,
      "title": _vm.$t('handover.agreement.update.title'),
      "rightDisabled": !_vm.updateFormValid,
      "rightLoading": _vm.isUpdateLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isEditDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isEditDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isEditDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isEditDialogActive = false;
      },
      "rightClick": _vm.updateAgreement
    }
  }, [_c('v-form', {
    model: {
      value: _vm.updateFormValid,
      callback: function callback($$v) {
        _vm.updateFormValid = $$v;
      },
      expression: "updateFormValid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    ref: "descriptionTextField",
    attrs: {
      "rules": _vm.requiredRule,
      "outlined": "",
      "label": _vm.$t('objects.handover.title')
    },
    model: {
      value: _vm.editDescription,
      callback: function callback($$v) {
        _vm.editDescription = $$v;
      },
      expression: "editDescription"
    }
  })], 1)], 1)], 1)], 1), _c('confirm-action-dialog', {
    attrs: {
      "loading": _vm.isDeleteLoading,
      "isDialogActive": _vm.isDeleteDialogActive,
      "title": _vm.$t('handover.agreement.delete.title'),
      "rightLoading": _vm.isDeleteLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isDeleteDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.deleteAgreement
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }