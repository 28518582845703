var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ticket-table', {
    staticClass: "ma-2",
    attrs: {
      "loadingTickets": _vm.loadingTickets,
      "loadingSideCard": _vm.loadingUpdate,
      "loadingPartnerUsers": _vm.loadingPartnerUsers,
      "loadingProjects": _vm.loadingProjects,
      "partnerId": _vm.partnerId,
      "refs": _vm.refs,
      "showVehicleHeader": _vm.showVehicleHeader
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.$emit('refresh');
      },
      "created": function created(d) {
        return _vm.initTicketDetail(d.number);
      },
      "openDetail": function openDetail(d) {
        return _vm.initTicketDetail(d.number);
      },
      "delete": _vm.deleteTicket
    }
  }), _c('v-slide-x-reverse-transition', [_vm.isSideCard ? _c('ticket-side-card', {
    attrs: {
      "loading": _vm.loadingUpdate || _vm.loadingTicket,
      "partnerId": _vm.partnerId,
      "viewId": _vm.viewId,
      "refs": _vm.refs
    },
    on: {
      "close": _vm.close,
      "update": _vm.updateTicket,
      "updateTicketStatus": _vm.updateTicketStatus,
      "updateCustomFieldValues": _vm.updateCustomFieldValues,
      "updateReferences": _vm.updateTicket,
      "deleteTicket": _vm.onDelete,
      "delete:assignees": _vm.createDeleteAssigneeActivity,
      "add:assignees": _vm.createAddAssigneeActivity
    },
    model: {
      value: _vm.ticket,
      callback: function callback($$v) {
        _vm.ticket = $$v;
      },
      expression: "ticket"
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }