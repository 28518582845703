var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4"
  }, [_c('h3', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.$t("components.partner.PartnerManagerList.title")))]), _c('v-spacer'), _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.CREATE,
      "a": _vm.ResourceEnum.SETTING_PARTNER_INTERNAL,
      "field": _vm.partnerId
    }
  }, [_c('partner-manage-initialize-dialog'), _c('v-menu', {
    attrs: {
      "auto": "",
      "offset-y": "",
      "flat": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var onMenu = _ref.on;
        return [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var onTooltip = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": "",
                  "data-test-setup-selection": ""
                }
              }, Object.assign({}, onMenu, onTooltip)), [_c('v-icon', [_vm._v("mdi-playlist-plus")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerManagerList.setUpWizardSelection")))])])];
      }
    }])
  }, [_c('v-list', {
    attrs: {
      "elevation": "0"
    }
  }, _vm._l(_vm.setupList, function (setup, index) {
    return _c('div', {
      key: index
    }, [setup.isActive ? _c('v-list-item', [_c('v-btn', {
      staticStyle: {
        "width": "100% float: left"
      },
      attrs: {
        "plain": "",
        "data-test-setup-selection-element": ""
      },
      on: {
        "click": setup.onClick
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(setup.text)) + " "), setup.isSetup ? _c('v-icon', {
      attrs: {
        "color": "primary"
      }
    }, [_vm._v("mdi-check")]) : _vm._e()], 1)], 1) : _vm._e()], 1);
  }), 0)], 1)], 1)], 1), _c('v-card', {
    staticClass: "flex-container",
    attrs: {
      "flat": ""
    }
  }, [_c('filter-card', {
    attrs: {
      "search": _vm.search,
      "filterList": _vm.filterList,
      "filterItems": _vm.allPartnerSettings,
      "filteredItems": _vm.filteredItems
    },
    on: {
      "update:search": function updateSearch($event) {
        _vm.search = $event;
      }
    }
  }), _c('div', {
    staticClass: "scrollable"
  }, [_vm._l(_vm.filteredItems, function (item) {
    return _c('v-list', {
      key: item.id,
      attrs: {
        "two-line": ""
      }
    }, [_c('v-list-item', {
      staticClass: "item",
      class: "".concat(_vm.isActive(item)),
      on: {
        "click": function click($event) {
          return _vm.set(item);
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.key) + " ")]), _c('v-list-item-subtitle', {
      staticClass: "text--primary"
    }), _c('v-list-item-subtitle')], 1), _c('v-list-item-action', [_c('v-list-item-action-text', [_vm._v(_vm._s(_vm.date(item)))]), _c('v-icon', {
      attrs: {
        "color": "grey lighten-1"
      }
    })], 1)], 1)], 1);
  }), _vm.filteredItems.length == 0 ? _c('div', {
    staticClass: "emptyDataContainer"
  }, [_c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on;
        return [_c('v-img', _vm._g({
          attrs: {
            "src": _vm.emptyDataSrc,
            "height": "100",
            "width": "200",
            "contain": ""
          }
        }, on))];
      }
    }], null, false, 974937243)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerReportList.noData")))])])], 1) : _vm._e()], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }