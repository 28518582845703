var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_vm.isLoading ? _c('v-container', [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, actions, heading, table-row, list-item-two-line@4"
    }
  })], 1) : _vm.partner ? _c('v-container', [_c('m-header', {
    attrs: {
      "title": _vm.title,
      "breadCrumbs": _vm.breadCrumbs
    }
  }), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('create-report-form', {
    attrs: {
      "active": {
        report: true,
        contact: true,
        message: true
      },
      "dto": _vm.report
    },
    on: {
      "update:dto": function updateDto($event) {
        _vm.report = $event;
      }
    },
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "loading": _vm.isLoading,
      "outlined": "",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        _vm.report = new _vm.CreateReportAsPartnerDto();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("reset")) + " ")]), _c('v-btn', {
    attrs: {
      "elevation": 0,
      "loading": _vm.isLoading,
      "color": "info",
      "disabled": !_vm.isValid,
      "data-test-create-report": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportInitializeCard.create")) + " ")])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }